<template>
	<div class="ysm-order-two">
		<div align="left" class="left">
			<img src="../../assets/ysmimg/ysmorderoneimg.png" class="infoimg" width="90px" height="110px"
				style="vertical-align: middle;">
			<div>
				<h3 data-i18n="[html]forms.order.patient_information">治疗方案</h3>
				<p data-i18n="forms.order.patient_information_content">请在此处详细说明治疗方案</p>
				<p><span data-i18n="forms.order.select_jawbones">用"优诗美"治疗的牙列<span style="color: red;">*</span></span>
				</p>
				<el-radio-group v-model="radio" class="heradiogroup" @change="selectHe">
					<el-radio class="he" :label="1">上下双颌</el-radio>
					<el-radio class="he" :label="2">上颌</el-radio>
					<el-radio class="he" :label="3">下颌</el-radio>
				</el-radio-group>
				<div v-show="isVisiable" style="margin-top: 1.275rem;">
					<p>另一个牙列是否接受了其他治疗？</p>
					<el-radio-group v-model="zradio" class="heradiogroup" @change="selectYn">
						<el-radio class="he" :label="1">是</el-radio>
						<el-radio class="he" :label="2">否</el-radio>
					</el-radio-group>

				</div>
			</div>
		</div>
		<div class="con">
			<div class="right">
				<el-row>
					<el-col :span="24">
						<div id="new-medical-report-ctrl">
							<div class="medical-report-ctrl" align="left">
								<span style="display: inline-block;margin-bottom: 0.625rem;">请输入完整的医学检查</span>
								<br />
								<table class="container-table">
									<tbody>
										<tr>
											<td class="label-column">右</td>
											<td class="label-column-space">
												<div></div>
											</td>
											<td>
												<table class="report-table">
													<tbody>
														<tr class="select-row">
															<td>
																<div @click="getFind('a18',18)" id="a18"></div>
															</td>
															<td>
																<div @click="getFind('a17',17)" id="a17"></div>
															</td>
															<td>
																<div @click="getFind('a16',16)" id="a16"></div>
															</td>
															<td>
																<div @click="getFind('a15',15)" id="a15"></div>
															</td>
															<td>
																<div @click="getFind('a14',14)" id="a14"></div>
															</td>
															<td>
																<div @click="getFind('a13',13)" id="a13"></div>
															</td>
															<td>
																<div @click="getFind('a12',12)" id="a12"></div>
															</td>
															<td>
																<div @click="getFind('a11',11)" id="a11"></div>
															</td>
															<td>
																<div @click="getFind('a21',21)" id="a21"></div>
															</td>
															<td>
																<div @click="getFind('a22',22)" id="a22"></div>
															</td>
															<td>
																<div @click="getFind('a23',23)" id="a23"></div>
															</td>
															<td>
																<div @click="getFind('a24',24)" id="a24"></div>
															</td>
															<td>
																<div @click="getFind('a25',25)" id="a25"></div>
															</td>
															<td>
																<div @click="getFind('a26',26)" id="a26"></div>
															</td>
															<td>
																<div @click="getFind('a27',27)" id="a27"></div>
															</td>
															<td>
																<div @click="getFind('a28',28)" id="a28"></div>
															</td>
														</tr>
														<tr class="label-row">
															<td>8</td>
															<td>7</td>
															<td>6</td>
															<td>5</td>
															<td>4</td>
															<td>3</td>
															<td>2</td>
															<td>1</td>
															<td>1</td>
															<td>2</td>
															<td>3</td>
															<td>4</td>
															<td>5</td>
															<td>6</td>
															<td>7</td>
															<td>8</td>
														</tr>
														<tr class="label-row">
															<td>8</td>
															<td>7</td>
															<td>6</td>
															<td>5</td>
															<td>4</td>
															<td>3</td>
															<td>2</td>
															<td>1</td>
															<td>1</td>
															<td>2</td>
															<td>3</td>
															<td>4</td>
															<td>5</td>
															<td>6</td>
															<td>7</td>
															<td>8</td>
														</tr>
														<tr class="select-row">
															<td>
																<div @click="getFind('a48',48)" id="a48"></div>
															</td>
															<td>
																<div @click="getFind('a47',47)" id="a47"></div>
															</td>
															<td>
																<div @click="getFind('a46',46)" id="a46"></div>
															</td>
															<td>
																<div @click="getFind('a45',45)" id="a45"></div>
															</td>
															<td>
																<div @click="getFind('a44',44)" id="a44"></div>
															</td>
															<td>
																<div @click="getFind('a43',43)" id="a43"></div>
															</td>
															<td>
																<div @click="getFind('a42',42)" id="a42"></div>
															</td>
															<td>
																<div @click="getFind('a41',41)" id="a41"></div>
															</td>
															<td>
																<div @click="getFind('a31',31)" id="a31"></div>
															</td>
															<td>
																<div @click="getFind('a32',32)" id="a32"></div>
															</td>
															<td>
																<div @click="getFind('a33',33)" id="a33"></div>
															</td>
															<td>
																<div @click="getFind('a34',34)" id="a34"></div>
															</td>
															<td>
																<div @click="getFind('a35',35)" id="a35"></div>
															</td>
															<td>
																<div @click="getFind('a36',36)" id="a36"></div>
															</td>
															<td>
																<div @click="getFind('a37',37)" id="a37"></div>
															</td>
															<td>
																<div @click="getFind('a38',38)" id="a38"></div>
															</td>
														</tr>
													</tbody>
												</table>
											</td>
											<td class="label-column-space">
												<div></div>
											</td>
											<td class="label-column">左</td>
										</tr>
									</tbody>
								</table>
								<span class="jianchaitem">C =全冠义齿, PC =部分冠义齿, P = 桥体, I = 种植体, CC = 连冠义齿,Y = 嵌体, RT =
									根管治疗过</span>
								<br />
								<span class="jianchaitem">i.b. = 未完全萌出牙, AG = 先天缺失牙, V = 贴面,Ex = 已拔除牙</span>
								<br />

							</div>
						</div>
					</el-col>
				</el-row>
				<el-row style="margin-top: 0.625rem;">
					<el-col :span="24" style="text-align: left;">
						<span style="margin-bottom: 0.3125rem;display: inline-block;">请选择适用项</span>
						<el-checkbox-group v-model="checkList" style="margin-bottom: 0.3125rem;">
							<el-checkbox   :label="1" >牙周炎</el-checkbox>
							<el-checkbox :label="2">颞下颌关节紊乱TMJ</el-checkbox>
							<el-checkbox :label="3">其它(请注明)</el-checkbox>
						</el-checkbox-group>
						<el-input type="textarea" v-model="desc"></el-input>
					</el-col>
				</el-row>
				<el-row style="margin-top: 0.625rem;">
					<el-col :span="13">
						<div id="new-select-teeth-ctrl">
							<div class="teeth-selection-ctrl" style="text-align: left;">
								<span>以下牙齿需要移动(请选择)<span
										style="color: red;display: inline-block;margin-bottom: 0.3125rem;margin-top: 0.625rem;">*</span></span>
								<table class="container-table">
									<tbody>
										<tr>
											<th align="left">患者右侧</th>
											<th align="right">患者左测</th>
										</tr>
										<tr>
											<td colspan="2">
												<table class="selection-table">
													<tbody>
														<tr class="select-row sh select-row1">
															<td>
																<div class="ya17" id="ya17"
																	@click="showDuiHao1('ya17',17)"></div>
															</td>
															<td>
																<div id="ya16" class="ya16"
																	@click="showDuiHao1('ya16',16)"></div>
															</td>
															<td>
																<div id="ya15" class="ya15"
																	@click="showDuiHao1('ya15',15)"></div>
															</td>
															<td>
																<div id="ya14" class="ya14"
																	@click="showDuiHao1('ya14',14)"></div>
															</td>
															<td>
																<div id="ya13" class="ya13"
																	@click="showDuiHao1('ya13',13)"></div>
															</td>
															<td>
																<div id="ya12" class="ya12"
																	@click="showDuiHao1('ya12',12)"></div>
															</td>
															<td>
																<div id="ya11" class="ya11"
																	@click="showDuiHao1('ya11',11)"></div>
															</td>
															<td>
																<div id="ya21" class="ya21"
																	@click="showDuiHao2('ya21',21)"></div>
															</td>
															<td>
																<div id="ya22" class="ya22"
																	@click="showDuiHao2('ya22',22)"></div>
															</td>
															<td>
																<div id="ya23" class="ya23"
																	@click="showDuiHao2('ya23',23)"></div>
															</td>
															<td>
																<div id="ya24" class="ya24"
																	@click="showDuiHao2('ya24',24)"></div>
															</td>
															<td>
																<div id="ya25" class="ya25"
																	@click="showDuiHao2('ya25',25)"></div>
															</td>
															<td>
																<div id="ya26" class="ya26"
																	@click="showDuiHao2('ya26',26)"></div>
															</td>
															<td>
																<div id="ya27" class="ya27"
																	@click="showDuiHao2('ya27',27)"></div>
															</td>
														</tr>
														<tr class="teeth-row sh">
															<td>
																<div class="ya17" id="ya17"
																	@click="showDuiHao1('ya17',17)"><img
																		src="../../assets/ysmimg/Zaehne_1.png" />
																</div>
															</td>
															<td>
																<div class="ya16" id="ya16"
																	@click="showDuiHao1('ya16',16)"><img
																		src="../../assets/ysmimg/Zaehne_2.png" />
																</div>
															</td>
															<td>
																<div class="ya15" id="ya15"
																	@click="showDuiHao1('ya15',15)"><img
																		src="../../assets/ysmimg/Zaehne_3.png" />
																</div>
															</td>
															<td>
																<div class="ya14" id="ya14"
																	@click="showDuiHao1('ya14',14)"><img
																		src="../../assets/ysmimg/Zaehne_4.png" />
																</div>
															</td>
															<td>
																<div class="ya13" id="ya13"
																	@click="showDuiHao1('ya13',13)"><img
																		src="../../assets/ysmimg/Zaehne_5.png" />
																</div>
															</td>
															<td>
																<div class="ya12" id="ya12"
																	@click="showDuiHao1('ya12',12)"><img
																		src="../../assets/ysmimg/Zaehne_6.png" />
																</div>
															</td>
															<td>
																<div class="ya11" id="ya11"
																	@click="showDuiHao1('ya11',11)"><img
																		src="../../assets/ysmimg/Zaehne_7.png" />
																</div>
															</td>
															<td>
																<div class="ya21" id="ya21"
																	@click="showDuiHao2('ya21',21)"><img
																		src="../../assets/ysmimg/Zaehne_8.png" />
																</div>
															</td>
															<td>
																<div class="ya22" id="ya22"
																	@click="showDuiHao2('ya22',22)"><img
																		src="../../assets/ysmimg/Zaehne_9.png" />
																</div>
															</td>
															<td>
																<div class="ya23" id="ya23"
																	@click="showDuiHao2('ya23',23)"><img
																		src="../../assets/ysmimg/Zaehne_10.png" />
																</div>
															</td>
															<td>
																<div class="ya24" id="ya24"
																	@click="showDuiHao2('ya24',24)"><img
																		src="../../assets/ysmimg/Zaehne_11.png" />
																</div>
															</td>
															<td>
																<div class="ya25" id="ya25"
																	@click="showDuiHao2('ya25',25)"><img
																		src="../../assets/ysmimg/Zaehne_12.png" />
																</div>
															</td>
															<td>
																<div class="ya26" id="ya26"
																	@click="showDuiHao2('ya26',26)"><img
																		src="../../assets/ysmimg/Zaehne_13.png" />
																</div>
															</td>
															<td>
																<div class="ya27" id="ya27"
																	@click="showDuiHao2('ya27',27)"><img
																		src="../../assets/ysmimg/Zaehne_14.png" />
																</div>
															</td>
														</tr>
														<tr class="teeth-row xh">
															<td>
																<div class="ya47" @click="showDuiHao4('ya47',47)"><img
																		src="../../assets/ysmimg/Zaehne_15.png" />
																</div>
															</td>
															<td>
																<div class="ya46" @click="showDuiHao4('ya46',46)"><img
																		src="../../assets/ysmimg/Zaehne_16.png" />
																</div>
															</td>
															<td>
																<div class="ya45" @click="showDuiHao4('ya45',45)"><img
																		src="../../assets/ysmimg/Zaehne_17.png" />
																</div>
															</td>
															<td>
																<div class="ya44" @click="showDuiHao4('ya44',44)"><img
																		src="../../assets/ysmimg/Zaehne_18.png" />
																</div>
															</td>
															<td>
																<div class="ya43" @click="showDuiHao4('ya43',43)"><img
																		src="../../assets/ysmimg/Zaehne_19.png" />
																</div>
															</td>
															<td>
																<div class="ya42" @click="showDuiHao4('ya42',42)"><img
																		src="../../assets/ysmimg/Zaehne_20.png" />
																</div>
															</td>
															<td>
																<div class="ya41" @click="showDuiHao4('ya41',41)"><img
																		src="../../assets/ysmimg/Zaehne_21.png" />
																</div>
															</td>
															<td>
																<div class="ya31" @click="showDuiHao3('ya31',31)"><img
																		src="../../assets/ysmimg/Zaehne_22.png" />
																</div>
															</td>
															<td>
																<div class="ya32" @click="showDuiHao3('ya32',32)"><img
																		src="../../assets/ysmimg/Zaehne_23.png" />
																</div>
															</td>
															<td>
																<div class="ya33" @click="showDuiHao3('ya33',33)"><img
																		src="../../assets/ysmimg/Zaehne_24.png" />
																</div>
															</td>
															<td>
																<div class="ya34" @click="showDuiHao3('ya34',34)"><img
																		src="../../assets/ysmimg/Zaehne_25.png" />
																</div>
															</td>
															<td>
																<div class="ya35" @click="showDuiHao3('ya35',35)"><img
																		src="../../assets/ysmimg/Zaehne_26.png" />
																</div>
															</td>
															<td>
																<div class="ya36" @click="showDuiHao3('ya36',36)"><img
																		src="../../assets/ysmimg/Zaehne_27.png" />
																</div>
															</td>
															<td>
																<div class="ya37" @click="showDuiHao3('ya37',37)"><img
																		src="../../assets/ysmimg/Zaehne_28.png" />
																</div>
															</td>
														</tr>
														<tr class="select-row xh select-row1">
															<td>
																<div class="ya47" id="ya47"
																	@click="showDuiHao4('ya47',47)"></div>
															</td>
															<td>
																<div class="ya46" id="ya46"
																	@click="showDuiHao4('ya46',46)"></div>
															</td>
															<td>
																<div class="ya45" id="ya45"
																	@click="showDuiHao4('ya45',145)"></div>
															</td>
															<td>
																<div class="ya44" id="ya44"
																	@click="showDuiHao4('ya44',44)"></div>
															</td>
															<td>
																<div class="ya43" id="ya43"
																	@click="showDuiHao4('ya43',43)"></div>
															</td>
															<td>
																<div class="ya42" id="ya42"
																	@click="showDuiHao4('ya42',42)"></div>
															</td>
															<td>
																<div class="ya41" id="ya41"
																	@click="showDuiHao4('ya41',41)"></div>
															</td>
															<td>
																<div class="ya31" id="ya31"
																	@click="showDuiHao3('ya31',31)"></div>
															</td>
															<td>
																<div class="ya32" id="ya32"
																	@click="showDuiHao3('ya32',32)"></div>
															</td>
															<td>
																<div class="ya33" id="ya33"
																	@click="showDuiHao3('ya33',33)"></div>
															</td>
															<td>
																<div class="ya34" id="ya34"
																	@click="showDuiHao3('ya34',34)"></div>
															</td>
															<td>
																<div class="ya35" id="ya35"
																	@click="showDuiHao3('ya35',35)"></div>
															</td>
															<td>
																<div class="ya36" id="ya36"
																	@click="showDuiHao3('ya36',36)"></div>
															</td>
															<td>
																<div class="ya37" id="ya37"
																	@click="showDuiHao3('ya37',37)"></div>
															</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
									</tbody>
								</table>
								<span>*为必填项</span>
							</div>
						</div>
					</el-col>
				</el-row>
				<div align="right">
					<el-button class="back" type="primary" @click="back">返回</el-button>
					<el-button class="nextStep" type="primary" @click="nextStepOrder">下一步</el-button>
				</div>
			</div>
		</div>
		<el-dialog title="医学发现" :visible.sync="dialogFormVisible" @close="destroy" :top="top">
			<div
				style="display: flex;border-bottom: 1px solid #e5e5e5;border-top: 1px solid #e5e5e5;justify-content: center;align-items: center;">
				<img :src="srcImg" class="ei" />
				<el-form :model="form" :inline="true" class="demo-form-inline">
					<el-form-item>
						<el-checkbox-group style="text-align: left;" v-model="form.type" @change="selItem">
							<el-checkbox style="display: block;" label="全冠义齿" name="type"></el-checkbox>
							<el-checkbox style="display: block;" label="部分冠义齿" name="type"></el-checkbox>
							<el-checkbox style="display: block;" label="桥体" name="type"></el-checkbox>
							<el-checkbox style="display: block;" label="种植体" name="type"></el-checkbox>
							<el-checkbox style="display: block;" label="连冠义齿" name="type"></el-checkbox>
							<el-checkbox style="display: block;" label="嵌体" name="type"></el-checkbox>
						</el-checkbox-group>
					</el-form-item>
					<el-form-item>
						<el-checkbox-group style="text-align: left;" v-model="form.type" @change="selItem">
							<el-checkbox style="display: block;" label="根管治疗过" name="type"></el-checkbox>
							<el-checkbox style="display: block;" label="未完全萌出牙" name="type"></el-checkbox>
							<el-checkbox style="display: block;" label="先天缺失牙" name="type"></el-checkbox>
							<el-checkbox style="display: block;" label="贴面" name="type"></el-checkbox>
							<el-checkbox style="display: block;" label="已拔除牙" name="type"></el-checkbox>
						</el-checkbox-group>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button class="c" @click="cancel">取 消</el-button>
				<el-button class="q" type="primary" @click="confirmSel">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		Message
	} from "element-ui"
	export default {
		data: function() {
			return {
				srcImg:"",
				zradio: 2,
				isVisiable: false,
				zuoshanglist: [], //储的左上哪些牙要移动
				youshanglist: [], //储的右上哪些牙要移动
				youxialist: [], //存储的右下哪些牙要移动
				zuoxialist: [], //储的左下哪些牙要移动
				checkList: [],
				desc: '', //备注说明
				radio: 1,
				dialogFormVisible: false, //对话框默认是隐藏的
				form: {
					type: []
				},
				top: "300px",
				findContentEnglish: ['C', 'PC', 'P', 'I', 'CC', 'Y', 'RT', 'i.b.', 'AG', 'V', 'Ex'],
				findContentChinese: ['全冠义齿', '部分冠义齿', '桥体', '种植体', '连冠义齿', '嵌体', '根管治疗过', '未完全萌出牙', '先天缺失牙', '贴面',
					'已拔除牙'
				],
				yaId: '', //32颗牙的id,
				ya32Num: '',
				yaobj: {
					yalie: '',
					zhiguo: '',
					jiancha: [],
					yidong: [],
					noyidong: [],
					oid: '',
					shiyongxiang:[],
					shuoming:''
				}
			}
		},
		methods: {
			selectYn() {

			},
			// dialog 取消按钮单击事件
			cancel() {
				this.form.type = [];
				this.dialogFormVisible = false
			},
			//单击dialog的确定按钮
			confirmSel() {
				//this.form.type里面是汉字
				let str = "";
				let arr = []; //选中的元素在this.findContentChinese中的下标
				if (this.form.type.length > 0) {
					for (let i = 0; i < this.form.type.length; i++) {
						let nu = this.findContentChinese.indexOf(this.form.type[i]);
						arr.push(nu);
						str += this.findContentEnglish[nu];
						if (i != this.form.type.length - 1) {
							str += ",";
						}
					}
					//将英文内容追加到div中
					document.getElementById(this.yaId).innerHTML = str;
					//this.form.type中的汉字决定哪些牙齿变灰色
					this.changeGray(this.form.type, this.ya32Num);
				} else {
					//将英文内容追加到div中
					document.getElementById(this.yaId).innerHTML = str;
					this.changeGray(this.form.type, this.ya32Num);
				}

				//关闭dialog
				this.dialogFormVisible = false;
			},
			//dialog关闭后执行的回调函数
			destroy() {
				this.form.type = [];
			},
			//每一颗牙齿都正常,不灰色
			normalColor(num) {
				if (num == 18 || num == 28 || num == 38 || num == 48) {
					num = num - 1;
					let cla = 'ya' + num;
					document.getElementsByClassName(cla)[0].setAttribute("class", cla);
					document.getElementsByClassName(cla)[0].removeAttribute("style");
					document.getElementsByClassName(cla)[1].setAttribute("class", cla);
					document.getElementsByClassName(cla)[1].removeAttribute("style");
				} else if (num == 11) {
					let a = 'ya' + num;
					let b = 'ya' + (num + 1);
					let c = 'ya21';

					document.getElementsByClassName(a)[0].setAttribute("class", a);
					document.getElementsByClassName(a)[0].removeAttribute("style");
					document.getElementsByClassName(a)[1].setAttribute("class", a);
					document.getElementsByClassName(a)[1].removeAttribute("style");
					//
					// document.getElementsByClassName(b)[0].setAttribute("class", b);
					// document.getElementsByClassName(b)[0].removeAttribute("style");
					// document.getElementsByClassName(b)[1].setAttribute("class", b);
					// document.getElementsByClassName(b)[1].removeAttribute("style");
					//
					// document.getElementsByClassName(c)[0].setAttribute("class", c);
					// document.getElementsByClassName(c)[0].removeAttribute("style");
					// document.getElementsByClassName(c)[1].setAttribute("class", c);
					// document.getElementsByClassName(c)[1].removeAttribute("style");
				} else if (num == 21) { //21 22 11
					let a = 'ya' + num;
					let b = 'ya' + (num + 1);
					let c = 'ya11';
					document.getElementsByClassName(a)[0].setAttribute("class", a);
					document.getElementsByClassName(a)[0].removeAttribute("style");
					document.getElementsByClassName(a)[1].setAttribute("class", a);
					document.getElementsByClassName(a)[1].removeAttribute("style");
					//
					// document.getElementsByClassName(b)[0].setAttribute("class", b);
					// document.getElementsByClassName(b)[0].removeAttribute("style");
					// document.getElementsByClassName(b)[1].setAttribute("class", b);
					// document.getElementsByClassName(b)[1].removeAttribute("style");
					//
					// document.getElementsByClassName(c)[0].setAttribute("class", c);
					// document.getElementsByClassName(c)[0].removeAttribute("style");
					// document.getElementsByClassName(c)[1].setAttribute("class", c);
					// document.getElementsByClassName(c)[1].removeAttribute("style");
				} else if (num == 31) { //31 32 41
					let a = 'ya' + num;
					let b = 'ya' + (num + 1);
					let c = 'ya41';
					document.getElementsByClassName(a)[0].setAttribute("class", a);
					document.getElementsByClassName(a)[0].removeAttribute("style");
					document.getElementsByClassName(a)[1].setAttribute("class", a);
					document.getElementsByClassName(a)[1].removeAttribute("style");
					//
					// document.getElementsByClassName(b)[0].setAttribute("class", b);
					// document.getElementsByClassName(b)[0].removeAttribute("style");
					// document.getElementsByClassName(b)[1].setAttribute("class", b);
					// document.getElementsByClassName(b)[1].removeAttribute("style");
					//
					// document.getElementsByClassName(c)[0].setAttribute("class", c);
					// document.getElementsByClassName(c)[0].removeAttribute("style");
					// document.getElementsByClassName(c)[1].setAttribute("class", c);
					// document.getElementsByClassName(c)[1].removeAttribute("style");
				} else if (num == 41) { //41 42 31
					let a = 'ya' + num;
					let b = 'ya' + (num + 1);
					let c = 'ya31';
					document.getElementsByClassName(a)[0].setAttribute("class", a);
					document.getElementsByClassName(a)[0].removeAttribute("style");
					document.getElementsByClassName(a)[1].setAttribute("class", a);
					document.getElementsByClassName(a)[1].removeAttribute("style");
					//
					// document.getElementsByClassName(b)[0].setAttribute("class", b);
					// document.getElementsByClassName(b)[0].removeAttribute("style");
					// document.getElementsByClassName(b)[1].setAttribute("class", b);
					// document.getElementsByClassName(b)[1].removeAttribute("style");
					//
					// document.getElementsByClassName(c)[0].setAttribute("class", c);
					// document.getElementsByClassName(c)[0].removeAttribute("style");
					// document.getElementsByClassName(c)[1].setAttribute("class", c);
					// document.getElementsByClassName(c)[1].removeAttribute("style");
				} else if (num == 17 || num == 27 || num == 37 || num == 47) {
					let a = 'ya' + num;
					let b = 'ya' + (num - 1);
					// document.getElementsByClassName(b)[0].setAttribute("class", b);
					// document.getElementsByClassName(b)[0].removeAttribute("style");
					// document.getElementsByClassName(b)[1].setAttribute("class", b);
					// document.getElementsByClassName(b)[1].removeAttribute("style");
					//
					document.getElementsByClassName(a)[0].setAttribute("class", a);
					document.getElementsByClassName(a)[0].removeAttribute("style");
					document.getElementsByClassName(a)[1].setAttribute("class", a);
					document.getElementsByClassName(a)[1].removeAttribute("style");
				} else {
					let a = 'ya' + num;
					let b = 'ya' + (num - 1);
					let c = 'ya' + (num + 1);

					// document.getElementsByClassName(a)[0].setAttribute("class", a);
					// document.getElementsByClassName(a)[0].removeAttribute("style");
					// document.getElementsByClassName(a)[1].setAttribute("class", a);
					// document.getElementsByClassName(a)[1].removeAttribute("style");
					let bcon = document.getElementById('a' + (num - 1)).innerHTML;
					let ccon = document.getElementById('a' + (num + 1)).innerHTML;
					if (bcon == "" && ccon == "") {
						document.getElementsByClassName(a)[0].setAttribute("class", a);
						document.getElementsByClassName(a)[0].removeAttribute("style");
						document.getElementsByClassName(a)[1].setAttribute("class", a);
						document.getElementsByClassName(a)[1].removeAttribute("style");
						// document.getElementsByClassName(b)[0].setAttribute("class", b);
						// document.getElementsByClassName(b)[0].removeAttribute("style");
						// document.getElementsByClassName(b)[1].setAttribute("class", b);
						// document.getElementsByClassName(b)[1].removeAttribute("style");
					} else {
						if (bcon != '') {
							let bconArray = bcon.split(',');
							if (this.isZybGray(bconArray, num - 1) == false) {
								// document.getElementsByClassName(b)[0].setAttribute("class", b);
								// document.getElementsByClassName(b)[0].removeAttribute("style");
								// document.getElementsByClassName(b)[1].setAttribute("class", b);
								// document.getElementsByClassName(b)[1].removeAttribute("style");
								document.getElementsByClassName(a)[0].setAttribute("class", a);
								document.getElementsByClassName(a)[0].removeAttribute("style");
								document.getElementsByClassName(a)[1].setAttribute("class", a);
								document.getElementsByClassName(a)[1].removeAttribute("style");
							}
						}
						if (ccon != "") {
							let cconArray = ccon.split(",");
							if (this.isZybGray(cconArray, num - 1) == false) {
								// document.getElementsByClassName(b)[0].setAttribute("class", b);
								// document.getElementsByClassName(b)[0].removeAttribute("style");
								// document.getElementsByClassName(b)[1].setAttribute("class", b);
								// document.getElementsByClassName(b)[1].removeAttribute("style");
								document.getElementsByClassName(a)[0].setAttribute("class", a);
								document.getElementsByClassName(a)[0].removeAttribute("style");
								document.getElementsByClassName(a)[1].setAttribute("class", a);
								document.getElementsByClassName(a)[1].removeAttribute("style");
							}
						}


					}


					// if (ccon == "") {
					// 	document.getElementsByClassName(c)[0].setAttribute("class", c);
					// 	document.getElementsByClassName(c)[0].removeAttribute("style");
					// 	document.getElementsByClassName(c)[1].setAttribute("class", c);
					// 	document.getElementsByClassName(c)[1].removeAttribute("style");
					// } else {
					// 	let cconArray = ccon.split(",");
					// 	if (this.isZybGray(cconArray, num + 1) == false && this.isbGray(cconArray, num + 1) == false) {
					// 		document.getElementsByClassName(c)[0].setAttribute("class", c);
					// 		document.getElementsByClassName(c)[0].removeAttribute("style");
					// 		document.getElementsByClassName(c)[1].setAttribute("class", c);
					// 		document.getElementsByClassName(c)[1].removeAttribute("style");
					// 	}
					// }
				}


			},
			//判断是否存在左右本牙灰
			isZybGray(arr, num) {
				let zybgrayArr = ['P', 'CC', 'i.b.'];
				let zybflag = false; //左右本牙灰
				//判断是否存在左右本牙灰
				if (arr.length > 0) {
					for (let i = 0; i < arr.length; i++) {
						if (zybgrayArr.indexOf(arr[i]) != -1) {
							zybflag = true;
							break;
						}
					}
				}
				return zybflag;
			},
			//判断是否存在本牙灰
			isbGray(arr, num) {
				let bgrayArr = ['I', 'RT', 'AG', 'Ex'];
				let bflag = false; //本牙灰
				//判断是否存在本牙灰
				if (arr.length > 0) {
					for (let i = 0; i < arr.length; i++) {
						if (bgrayArr.indexOf(arr[i]) != -1) {
							bflag = true;
							break;
						}
					}
				}
				return bflag;
			},
			//让牙齿变成灰色
			changeGray(arr, num) {
				let zybgrayArr = ['桥体', '连冠义齿', '未完全萌出牙'];
				let bgrayArr = ['种植体', '根管治疗过', '先天缺失牙', '已拔除牙'];
				let zybflag = false; //左右本牙灰
				let bflag = false; //本牙灰
				if (arr.length > 0) {
					//判断是否存在左右本牙灰
					for (let i = 0; i < arr.length; i++) {
						if (zybgrayArr.indexOf(arr[i]) != -1) {
							zybflag = true;
							break;
						}
					}
					if (zybflag == false) {
						//判断是否存在本牙灰
						for (let i = 0; i < arr.length; i++) {
							if (bgrayArr.indexOf(arr[i]) != -1) {
								bflag = true;
								break;
							}
						}
					}
				}


				if (zybflag) { //含有左右本牙灰
					if (num == 18) {
						let oldClass1 = document.getElementsByClassName("ya17")[0].getAttribute("class");
						let oldClass2 = document.getElementsByClassName("ya17")[1].getAttribute("class");
						document.getElementsByClassName("ya17")[0].setAttribute("class", oldClass1 + " hei");
						document.getElementsByClassName("ya17")[1].setAttribute("class", oldClass2 + " hei");
						document.getElementsByClassName("ya17")[1].style.backgroundColor='#CCC'
						console.log(document.getElementsByClassName("ya17")[1]);
						//禁用yayi对应的div的单击事件
						document.getElementsByClassName("ya17")[0].setAttribute("style", "pointer-events:none");
						document.getElementsByClassName("ya17")[1].setAttribute("style", "pointer-events:none")
						document.getElementsByClassName("ya17")[1].style.backgroundColor='#CCC';
					} else if (num == 48) {
						let oldClass1 = document.getElementsByClassName("ya47")[0].getAttribute("class");
						let oldClass2 = document.getElementsByClassName("ya47")[1].getAttribute("class");
						document.getElementsByClassName("ya47")[0].setAttribute("class", oldClass1 + " hei");
						document.getElementsByClassName("ya47")[1].setAttribute("class", oldClass2 + " hei");
						//禁用yayi对应的div的单击事件
						document.getElementsByClassName("ya47")[0].setAttribute("style", "pointer-events:none;background-color:#ccc");
						document.getElementsByClassName("ya47")[1].setAttribute("style", "pointer-events:none")
					    document.getElementsByClassName("ya47")[0].style.backgroundColor="#CCCCCC";
					} else if (num == 28) {
						let oldClass1 = document.getElementsByClassName("ya27")[0].getAttribute("class");
						let oldClass2 = document.getElementsByClassName("ya27")[1].getAttribute("class");
						document.getElementsByClassName("ya27")[0].setAttribute("class", oldClass1 + " hei");
						document.getElementsByClassName("ya27")[1].setAttribute("class", oldClass2 + " hei");
						//禁用yayi对应的div的单击事件
						document.getElementsByClassName("ya27")[0].setAttribute("style", "pointer-events:none");
						document.getElementsByClassName("ya27")[1].setAttribute("style", "pointer-events:none");
						document.getElementsByClassName("ya27")[1].style.backgroundColor='#CCC';
					} else if (num == 38) {
						let oldClass1 = document.getElementsByClassName("ya37")[0].getAttribute("class");
						let oldClass2 = document.getElementsByClassName("ya37")[1].getAttribute("class");
						document.getElementsByClassName("ya37")[0].setAttribute("class", oldClass1 + " hei");
						document.getElementsByClassName("ya37")[1].setAttribute("class", oldClass2 + " hei");
						//禁用yayi对应的div的单击事件
						document.getElementsByClassName("ya37")[0].setAttribute("style", "pointer-events:none;background-color:#ccc");
						document.getElementsByClassName("ya37")[1].setAttribute("style", "pointer-events:none")
					     document.getElementsByClassName("ya37")[0].style.backgroundColor="#CCCCCC";
					} else if (num == 11 || num == 41 || num == 21 || num == 31) { //11 12 21都是灰色
						this.grayy(num);
					} else {
						this.grayy(num);
					}
				} else if (bflag) { //走这个elseif说明左右本牙灰就不存在,就是本牙灰了
					if (num == 18 || num == 28 || num == 38 || num == 48) {
						if (num == 18) {
							let con17 = document.getElementById("a17").innerHTML;
							let arr17 = con17.split(",");
							let con16 = document.getElementById("a16").innerHTML;
							let arr16 = con16.split(",");
							if (this.isZybGray(arr17, 17) || this.isbGray(arr17, 17) || this.isZybGray(arr16, 16)) {

							} else {
								this.normalColor(17);
							}
						}else if(num == 28){
							let con27 = document.getElementById("a27").innerHTML;
							let arr27 = con17.split(",");
							let con26 = document.getElementById("a26").innerHTML;
							let arr26 = con16.split(",");
							if (this.isZybGray(arr27, 27) || this.isbGray(arr27, 27) || this.isZybGray(arr26, 26)) {
							
							} else {
								this.normalColor(27);
							}
						}else if(num==38){
							let con37 = document.getElementById("a37").innerHTML;
							let arr37 = con17.split(",");
							let con36 = document.getElementById("a36").innerHTML;
							let arr36 = con16.split(",");
							if (this.isZybGray(arr37, 37) || this.isbGray(arr37, 37) || this.isZybGray(arr36, 36)) {
							
							} else {
								this.normalColor(37);
							}
						}else if(num==48){
							let con47 = document.getElementById("a47").innerHTML;
							let arr47 = con17.split(",");
							let con46 = document.getElementById("a46").innerHTML;
							let arr46 = con16.split(",");
							if (this.isZybGray(arr47, 47) || this.isbGray(arr47, 47) || this.isZybGray(arr46, 46)) {
							
							} else {
								this.normalColor(47);
							}
						}

					} else if (num == 17 || num == 27 || num == 37 || num == 47) {
						//自己变黑
						let a = 'ya' + num;
						let oldClass1 = document.getElementsByClassName(a)[0].getAttribute("class");
						let oldClass2 = document.getElementsByClassName(a)[1].getAttribute("class");
						document.getElementsByClassName(a)[0].setAttribute("class", oldClass1 + " hei");
						document.getElementsByClassName(a)[1].setAttribute("class", oldClass2 + " hei");
						if(num == 17 || num == 27){
							document.getElementsByClassName(a)[1].style.backgroundColor="#CCCCCC";
							document.getElementsByClassName(a)[0].setAttribute("class", a + " hei");
						}else{
							document.getElementsByClassName(a)[0].style.backgroundColor="#CCCCCC";
							document.getElementsByClassName(a)[1].setAttribute("class", a + " hei");
						}
						//右边不黑
						let c = 'a' + (num - 1);//6
						let d = 'a' + (num - 2);//5
						let con6= document.getElementById(c).innerHTML;
						let arr6 = con6.split(",");
						let con5 = document.getElementById(d).innerHTML;
						let arr5 = con5.split(",");
						console.log(arr5);
						if(this.isZybGray(arr6,c)==false&&this.isbGray(arr6,c)==false&&this.isZybGray(arr5,d)==false){
							this.normalColor(num-1);
						}
						
					} else if (num == 11 || num == 21 || num == 31 || num == 41) {
						//自己变灰色
						let d = 'ya' + num;
						let oldClass1 = document.getElementsByClassName(d)[0].getAttribute("class");
						let oldClass2 = document.getElementsByClassName(d)[1].getAttribute("class");
						document.getElementsByClassName(d)[0].setAttribute("class", oldClass1 + " hei");
						document.getElementsByClassName(d)[1].setAttribute("class", oldClass2 + " hei");
						//禁用yayi对应的div的单击事件
						document.getElementsByClassName(d)[0].setAttribute("style", "pointer-events:none");
						document.getElementsByClassName(d)[1].setAttribute("style", "pointer-events:none")
						if(num==11||num==21){
							document.getElementsByClassName(d)[1].style.backgroundColor="#CCC";
							document.getElementsByClassName(d)[0].setAttribute("class", d + " hei");
						}else if(num==31||num==41){
							document.getElementsByClassName(d)[0].style.backgroundColor="#CCC";
							document.getElementsByClassName(d)[1].setAttribute("class", d + " hei");
						}
						
						if (num == 11) {
							//判断12是否灰不灰
							let con13 = document.getElementById("a13").innerHTML;
							let con12 = document.getElementById("a12").innerHTML;
							let arr13 = con13.split(",");
							let arr12 = con12.split(",");
							
							if (this.isZybGray(arr13,13)==false&&this.isZybGray(arr12,12)==false&&this.isbGray(arr12,12)==false) {
								this.normalColor(12);
							} 
							
							//判断21是否灰不灰
							let con21 = document.getElementById("a21").innerHTML;
							let con22 = document.getElementById("a22").innerHTML;
							let arr21 = con21.split(",");
							let arr22 = con22.split(",");
							if (this.isZybGray(arr22,22)==false&&this.isZybGray(arr21,21)==false&&this.isbGray(arr21,21)==false) {
								this.normalColor(21)
							} 
						}else if(num == 21){
							//判断11是否变灰
							let con11 = document.getElementById("a11").innerHTML;
							let con12 = document.getElementById("a12").innerHTML;
							let arr11 = con11.split(",");
							let arr12 = con12.split(",");
							
							if (this.isZybGray(arr12,12)==false&&this.isZybGray(arr11,11)==false&&this.isbGray(arr11,11)==false) {
								this.normalColor(11);
							} 
							//判断22是否变灰
							let con23 = document.getElementById("a23").innerHTML;
							let con22 = document.getElementById("a22").innerHTML;
							let arr23 = con23.split(",");
							let arr22 = con22.split(",");
							if (this.isZybGray(arr23,23)==false&&this.isZybGray(arr22,22)==false&&this.isbGray(arr22,22)==false) {
								this.normalColor(22)
							} 
							
						}else if(num == 31){
							//判断41是否变灰
							let con41 = document.getElementById("a41").innerHTML;
							let con42 = document.getElementById("a42").innerHTML;
							let arr41 = con41.split(",");
							let arr42 = con42.split(",");
							
							if (this.isZybGray(arr42,42)==false&&this.isZybGray(arr41,41)==false&&this.isbGray(arr41,41)==false) {
								this.normalColor(41);
							} 
							//判断32是否变灰
							let con33 = document.getElementById("a33").innerHTML;
							let con32 = document.getElementById("a32").innerHTML;
							let arr33 = con33.split(",");
							let arr32 = con32.split(",");
							if (this.isZybGray(arr33,33)==false&&this.isZybGray(arr32,32)==false&&this.isbGray(arr32,32)==false) {
								this.normalColor(32)
							} 
						}else if(num == 41){
							//判断42是否变灰
							let con43 = document.getElementById("a43").innerHTML;
							let con42 = document.getElementById("a42").innerHTML;
							let arr43 = con43.split(",");
							let arr42 = con42.split(",");
							
							if (this.isZybGray(arr43,43)==false&&this.isZybGray(arr42,42)==false&&this.isbGray(arr42,42)==false) {
								this.normalColor(42);
							} 
							//判断31是否变灰
							let con31 = document.getElementById("a31").innerHTML;
							let con32 = document.getElementById("a32").innerHTML;
							let arr31 = con31.split(",");
							let arr32 = con32.split(",");
							if (this.isZybGray(arr32,32)==false&&this.isZybGray(arr31,31)==false&&this.isbGray(arr31,31)==false) {
								this.normalColor(31)
							} 
						}
					} else { //其它本牙灰
						//自己变黑
						let a = 'ya' + num;
						let oldClass1 = document.getElementsByClassName(a)[0].getAttribute("class");
						let oldClass2 = document.getElementsByClassName(a)[1].getAttribute("class");
						document.getElementsByClassName(a)[0].setAttribute("class", oldClass1 + " hei");
						document.getElementsByClassName(a)[1].setAttribute("class", oldClass2 + " hei");
						//禁用yayi对应的div的单击事件
						document.getElementsByClassName(a)[0].setAttribute("style", "pointer-events:none");
						document.getElementsByClassName(a)[1].setAttribute("style", "pointer-events:none");
						if(num>=12&&num<=26){
							document.getElementsByClassName(a)[1].style.backgroundColor="#CCCCCC";
							document.getElementsByClassName(a)[0].setAttribute("class", a + " hei");
						}else{
							document.getElementsByClassName(a)[0].style.backgroundColor="#CCCCCC";
							document.getElementsByClassName(a)[1].setAttribute("class", a + " hei");
						}
						if(num!=12&&num!=22&&num!=32&&num!=42){
							//左右牙齿
							let b = 'ya' + (num + 1);
							let c = 'ya' + (num - 1);
							let a11='a'+(num+1);
							let a12='a'+(num+2);
							let b11='a'+(num-1);
							let b12='a'+(num-2);
							let cona11 = document.getElementById(a11).innerHTML;
							let cona12 = document.getElementById(a12).innerHTML;
							let conb11 = document.getElementById(b11).innerHTML;
							let conb12 = document.getElementById(b12).innerHTML;
							let arra11 = cona11.split(",");
							let arra12 = cona12.split(",");
							let arrb11 = conb11.split(",");
							let arrb12 = conb12.split(",");
							// //判断a11是否变灰
							if(this.isZybGray(arra12,a12)==false&&this.isZybGray(arra11,a11)==false&&this.isbGray(arra11,a11)==false){
								this.normalColor(num+1);
							}
							//判断b11是否变灰
							if(this.isZybGray(arrb12,b12)==false&&this.isZybGray(arrb11,b11)==false&&this.isbGray(arra11,b11)==false){
								this.normalColor(num-1);
							}
						}else{
							if(num=12){
								var a11='a'+(num+1);
								var a12='a'+(num+2);
								var b11='a'+(num-1);
								var b12='a'+(num+9);
							}else if(num==22){
								var a11='a'+(num+1);
								var a12='a'+(num+2);
								var b11='a'+(num-1);
								var b12='a'+(num-11);
							}else if(num==32){
								var a11='a'+(num+1);
								var a12='a'+(num+2);
								var b11='a'+(num-1);
								var b12='a'+(num+9);
							}else if(num==42){
								var a11='a'+(num+1);
								var a12='a'+(num+2);
								var b11='a'+(num-1);
								var b12='a'+(num-11);
							}
						// //左右牙齿
						// let b = 'ya' + (num + 1);
						// let c = 'ya' + (num - 1);
						
						// let cona11 = document.getElementById(a11).innerHTML;
						// let cona12 = document.getElementById(a12).innerHTML;
						// let conb11 = document.getElementById(b11).innerHTML;
						// let conb12 = document.getElementById(b12).innerHTML;
						// let arra11 = cona11.split(",");
						// let arra12 = cona12.split(",");
						// let arrb11 = conb11.split(",");
						// let arrb12 = conb12.split(",");
						// // //判断a11是否变灰
						// if(this.isZybGray(arra12,a12)==false&&this.isZybGray(arra11,a11)==false&&this.isbGray(arra11,a11)==false){
						// 	this.normalColor(num+1);
						// }
						// //判断b11是否变灰
						// if(this.isZybGray(arrb12,b12)==false&&this.isZybGray(arrb11,b11)==false&&this.isbGray(arra11,b11)==false){
						// 	this.normalColor(num-1);
						// }
						}
					}
				} else { //走这个if说明左右本牙以及本牙灰都没有了,但是要看旁边的牙齿是否有左右本牙灰色
					if (num == 18) {
						let con17 = document.getElementById("a17").innerHTML;
						let arr17 = [];
						let con16 = document.getElementById("a16").innerHTML;
						let arr16 = [];
						//17
						if (con16 == "" && con17 == "") {
							this.normalColor(17);
						} else if (con16 == "" && con17 != "") {
							arr17 = con17.split(',');
							if (this.isZybGray(arr17, 17) == false && this.isbGray(arr17, 17) == false) {
								this.normalColor(17)
							}
						} else if (con16 != "" && con17 == "") {
							arr16 = con16.split(',');
							if (this.isZybGray(arr16, 16) == false) {
								this.normalColor(17)
							}
						} else {
							arr17 = con17.split(',');
							arr16 = con16.split(',');
							if (this.isZybGray(arr17, 17) == false && this.isZybGray(arr16, 16) == false && this.isbGray(
									arr17, 17) == false) {
								this.normalColor(17)
							}
						}


						//如果16没有左右本牙灰,17没有左右本牙灰,本牙灰,那么17就灰色
						if (this.isZybGray(arr16, 16) == false && this.isZybGray(arr17, 17) == false && this.isbGray(arr17,
								17) == false) {
							this.normalColor(17)
						}



					} else if (num == 17) {
						let con18 = document.getElementById("a18").innerHTML;
						let con16 = document.getElementById("a16").innerHTML;
						let con15 = document.getElementById("a15").innerHTML;
						let arr18 = [];
						let arr16 = [];
						let arr15 = [];

						//18
						//16
						if (con15 == "" && con16 == "") {
							this.normalColor(16);
						} else if (con15 == "" && con16 != "") {
							arr16 = con16.split(',');
							if (this.isZybGray(arr16, 16) == false && this.isbGray(arr16, 16) == false) {
								this.normalColor(16)
							}
						} else if (con15 != "" && con16 == "") {
							arr15 = con15.split(',');
							if (this.isZybGray(arr15, 15) == false) {
								this.normalColor(16)
							}
						} else {
							arr15 = con15.split(',');
							arr16 = con16.split(',');
							if (this.isZybGray(arr15, 15) == false && this.isZybGray(arr16, 16) == false && this.isbGray(
									arr16, 16) == false) {
								this.normalColor(16)
							}
						}

						//如果18和16都没有左右本牙灰,则17不灰
						if (this.isZybGray(arr18, 18) == false && this.isZybGray(arr16, 16) == false) {
							this.normalColor(17)
						}
					} else if (num == 16) {
						let con15 = document.getElementById("a15").innerHTML;
						let con17 = document.getElementById("a17").innerHTML;
						let con18 = document.getElementById("a18").innerHTML;
						let con14 = document.getElementById("a14").innerHTML;
						let arr15 = [];
						let arr17 = [];
						let arr14 = [];
						let arr18 = [];
						//17
						if (con18 == "" && con17 == "") {
							this.normalColor(17);
						} else if (con18 == "" && con17 != "") {
							arr17 = con17.split(',');
							if (this.isZybGray(arr17, 17) == false && this.isbGray(arr17, 17) == false) {
								this.normalColor(17)
							}
						} else if (con18 != "" && con17 == "") {
							arr18 = con18.split(',');
							if (this.isZybGray(arr18, 18) == false) {
								this.normalColor(17)
							}
						} else {
							arr17 = con17.split(',');
							arr18 = con18.split(',');
							if (this.isZybGray(arr17, 17) == false && this.isZybGray(arr18, 18) == false&& this.isbGray(arr17, 17) == false) {
								this.normalColor(17)
							}
						}
						//15
						if (con14 == "" && con15 == "") {
							this.normalColor(15);
						} else if (con14 == "" && con15 != "") {
							arr15 = con15.split(',');
							if (this.isZybGray(arr15, 15) == false && this.isbGray(arr15, 15) == false) {
								this.normalColor(15)
							}
						} else if (con14 != "" && con15 == "") {
							arr14 = con14.split(',');
							if (this.isZybGray(arr14, 14) == false) {
								this.normalColor(15)
							}
						} else {
							arr15 = con15.split(',');
							arr14 = con14.split(',');
							if (this.isZybGray(arr15, 15) == false && this.isZybGray(arr14, 14) == false) {
								this.normalColor(15)
							}
						}
						//如果17,15不是左右本牙灰,16就不灰
						if (this.isZybGray(arr17, 17) == false && this.isZybGray(arr15, 15) == false) {
							this.normalColor(16)
						}
					} else if (num == 15) {
						let con16 = document.getElementById("a16").innerHTML;
						let con14 = document.getElementById("a14").innerHTML;
						let con13 = document.getElementById("a13").innerHTML;
						let con17 = document.getElementById("a17").innerHTML;
						let arr16 = [];
						let arr14 = [];
						let arr13 = [];
						let arr17 = [];
						//16
						if (con17 == "" && con16 == "") {
							this.normalColor(16);
						} else if (con17 == "" && con16 != "") {
							arr16 = con16.split(',');
							if (this.isZybGray(arr16, 16) == false && this.isbGray(arr16, 16) == false) {
								this.normalColor(16)
							}
						} else if (con17 != "" && con16 == "") {
							arr17 = con17.split(',');
							if (this.isZybGray(arr17, 17) == false) {
								this.normalColor(16)
							}
						} else {
							if (this.isZybGray(arr17, 17) == false && this.isZybGray(arr16, 16) == false) {
								this.normalColor(16)
							}
						}
						//14
						if (con14 == "" && con13 == "") {
							this.normalColor(14);
						} else if (con13 == "" && con14 != "") {
							arr14 = con14.split(',');
							if (this.isZybGray(arr14, 14) == false && this.isbGray(arr14, 14) == false) {
								this.normalColor(14)
							}
						} else if (con13 != "" && con14 == "") {
							arr13 = con13.split(',');
							if (this.isZybGray(arr13, 13) == false) {
								this.normalColor(14)
							}
						} else {
							if (this.isZybGray(arr13, 13) == false && this.isZybGray(arr14, 14) == false) {
								this.normalColor(14)
							}
						}

						//14和16都没有左右本牙灰,15就是灰色
						if (this.isZybGray(arr14, 14) == false && this.isZybGray(arr16, 16) == false) {
							this.normalColor(15);
						}
					} else if (num == 14) {
						console.log("贴面");
						let con13 = document.getElementById("a13").innerHTML;
						let con15 = document.getElementById("a15").innerHTML;
						let con12 = document.getElementById("a12").innerHTML;
						let con16 = document.getElementById("a16").innerHTML;
						let arr13 = [];
						let arr15 = [];
						let arr12 = [];
						let arr16 = [];
						console.log(con12);
						console.log(con13);
						//13
						if (con12 == "" && con13 == "") {
							console.log(1);
							this.normalColor(13);
						} else if (con12 == "" && con13 != "") {
							arr13 = con13.split(',');
							if (this.isZybGray(arr13, 12) == false && this.isbGray(arr13, 12) == false) {
								console.log(2);
								this.normalColor(13)
							}
						} else if (con12 != "" && con13 == "") {
							arr12 = con12.split(',');
							if (this.isZybGray(arr12, 12) == false) {
								console.log(3);
								this.normalColor(13)
							}
						} else {
							arr13 = con13.split(',');
							arr12 = con12.split(',');
							if (this.isZybGray(arr12, 12) == false && this.isZybGray(arr13, 13) == false && this.isbGray(
									arr13, 13) == false) {
								console.log(4);
								this.normalColor(13)
							}
						}
						//15
						if (con16 == "" && con15 == "") {
							this.normalColor(15);
						} else if (con16 == "" && con15 != "") {
							arr15 = con15.split(',');
							if (this.isZybGray(arr15, 15) == false && this.isbGray(arr15, 15) == false) {
								this.normalColor(15)
							}
						} else if (con16 != "" && con15 == "") {
							arr16 = con16.split(',');
							if (this.isZybGray(arr16, 16) == false) {
								this.normalColor(15)
							}
						} else {
							arr15 = con15.split(',');
							arr16 = con16.split(',');
							if (this.isbGray(arr15, 15) == false && this.isZybGray(arr16, 16) == false && this.isZybGray(
									arr15, 15) == false) {
								this.normalColor(15)
							}
						}

						//如果13,15不是左右本牙灰,14就不灰
						if (this.isZybGray(arr13, 13) == false && this.isZybGray(arr15, 15) == false) {
							this.normalColor(14)
						}

					} else if (num == 13) {
						let con12 = document.getElementById("a12").innerHTML;
						let con11 = document.getElementById("a11").innerHTML;
						let con14 = document.getElementById("a14").innerHTML;
						let con15 = document.getElementById("a15").innerHTML;
						let arr14 = [];
						let arr12 = [];
						let arr11 = [];
						let arr15 = [];
						//12
						if (con11 == "" && con12 == "") {
							this.normalColor(12);
						} else if (con11 == "" && con12 != "") {
							arr12 = con12.split(',');
							if (this.isZybGray(arr12, 12) == false && this.isbGray(arr12, 12) == false) {
								this.normalColor(12)
							}
						} else if (con11 != "" && con12 == "") {
							arr11 = con11.split(',');
							if (this.isZybGray(arr11, 11) == false) {
								this.normalColor(12)
							}
						} else {
							arr12 = con12.split(',');
							arr11 = con11.split(',');
							if (this.isZybGray(arr11, 11) == false && this.isZybGray(arr12, 12) == false) {
								this.normalColor(12)
							}
						}
						//14
						if (con15 == "" && con14 == "") {
							this.normalColor(14);
						} else if (con15 == "" && con14 != "") {
							arr14 = con14.split(',');
							if (this.isZybGray(arr14, 14) == false && this.isbGray(arr14, 14) == false) {
								this.normalColor(14)
							}
						} else if (con15 != "" && con14 == "") {
							arr15 = con15.split(',');
							if (this.isZybGray(arr15, 15) == false) {
								this.normalColor(14)
							}
						} else {
							arr14 = con14.split(',');
							arr15 = con15.split(',');
							if (this.isbGray(arr14, 14) == false && this.isZybGray(arr14, 14) == false && this.isZybGray(
									arr15, 15) == false) {
								this.normalColor(14)
							}
						}
						//如果12,14无左右本牙灰,13就不灰
						if (this.isZybGray(arr14, 14) == false && this.isZybGray(arr12, 12) == false) {
							this.normalColor(13);
						}

					} else if (num == 12) {
						let con11 = document.getElementById("a11").innerHTML;
						let con21 = document.getElementById("a21").innerHTML;
						let con13 = document.getElementById("a13").innerHTML;
						let con14 = document.getElementById("a14").innerHTML;
						let arr11 = [];
						let arr21 = [];
						let arr13 = [];
						let arr14 = [];
						//11
						if (con21 == "" && con11 == "") {
							this.normalColor(11);
						} else if (con21 == "" && con11 != "") {
							arr11 = con11.split(',');
							if (this.isZybGray(arr11, 11) == false && this.isbGray(arr11, 11) == false) {
								this.normalColor(11)
							}
						} else if (con21 != "" && con11 == "") {
							arr21 = con21.split(',');
							if (this.isZybGray(arr21, 21) == false) {
								this.normalColor(11)
							}
						} else {
							arr11 = con11.split(',');
							arr21 = con21.split(',');
							if (this.isZybGray(arr21, 21) == false && this.isZybGray(arr11, 11) == false) {
								this.normalColor(11)
							}
						}
						//13
						if (con14 == "" && con13 == "") {
							this.normalColor(13);
						} else if (con14 == "" && con13 != "") {
							arr13 = con13.split(',');
							if (this.isZybGray(arr13, 12) == false && this.isbGray(arr13, 13) == false) {
								this.normalColor(13)
							}
						} else if (con14 != "" && con13 == "") {
							arr14 = con14.split(',');
							if (this.isZybGray(con14, 14) == false) {
								this.normalColor(13)
							}
						} else {
							arr13 = con13.split(',');
							arr14 = con14.split(',');
							if (this.isZybGray(arr13, 12) == false && this.isZybGray(arr14, 14) == false) {
								this.normalColor(13)
							}
						}
						//11和13都没有左右本牙灰12就不灰
						if (this.isZybGray(arr11, 11) == false && this.isZybGray(arr13, 13) == false) {
							this.normalColor(12);
						}

					} else if (num == 11) {
						let con12 = document.getElementById("a12").innerHTML;
						let con13 = document.getElementById("a13").innerHTML;
						let arr12 = [];
						let arr13 = [];
						let con21 = document.getElementById("a21").innerHTML;
						let con22 = document.getElementById("a22").innerHTML;
						let arr21 = [];
						let arr22 = [];
						//12
						if (con13 == "" && con12 == "") {
							this.normalColor(12);
						} else if (con13 == "" && con12 != "") {
							arr12 = con12.split(',');
							if (this.isZybGray(arr12, 12) == false && this.isbGray(arr12, 12) == false) {
								this.normalColor(12)
							}
						} else if (con13 != "" && con12 == "") {
							arr13 = con13.split(',');
							if (this.isZybGray(arr13, 13) == false) {
								this.normalColor(12)
							}
						} else {
							arr12 = con12.split(',');
							arr13 = con13.split(',');
							if (this.isZybGray(arr13, 13) == false && this.isZybGray(arr12, 12) == false) {
								this.normalColor(12)
							}
						}
						//21
						if (con22 == "" && con21 == "") {
							this.normalColor(21);
						} else if (con22 == "" && con21 != "") {
							arr21 = con21.split(',');
							if (this.isZybGray(arr21, 21) == false && this.isbGray(arr21, 21) == false) {
								this.normalColor(21)
							}
						} else if (con22 != "" && con21 == "") {
							arr22 = con22.split(',');
							if (this.isZybGray(arr22, 22) == false) {
								this.normalColor(21)
							}
						} else {
							arr21 = con21.split(',');
							arr22 = con22.split(',');
							if (this.isZybGray(arr21, 21) == false && this.isZybGray(arr22, 22) == false) {
								this.normalColor(21)
							}
						}
						//如果12和21不是左右本牙灰,那么11就不灰了
						if (this.isZybGray(arr21, 21) == false && this.isZybGray(arr12, 12) == false) {
							this.normalColor(11);
						}
					} else if (num == 21) {
						let con12 = document.getElementById("a12").innerHTML;
						let con11 = document.getElementById("a11").innerHTML;
						let con22 = document.getElementById("a22").innerHTML;
						let con23 = document.getElementById("a23").innerHTML;
						let arr12 = [];
						let arr11 = [];
						let arr23 = [];
						let arr22 = [];
						//11
						if (con12 == "" && con11 == "") {
							this.normalColor(11);
						} else if (con12 == "" && con11 != "") {
							arr11 = con11.split(',');
							if (this.isZybGray(arr11, 11) == false && this.isbGray(arr11, 11) == false) {
								this.normalColor(11)
							}
						} else if (con12 != "" && con11 == "") {
							arr12 = con12.split(',');
							if (this.isZybGray(arr12, 12) == false) {
								this.normalColor(11)
							}
						} else {
							arr12 = con12.split(',');
							arr11 = con11.split(',');
							if (this.isZybGray(arr12, 12) == false && this.isZybGray(arr11, 11) == false) {
								this.normalColor(11)
							}
						}
						//22
						if (con23 == "" && con22 == "") {
							this.normalColor(22);
						} else if (con23 == "" && con22 != "") {
							arr22 = con22.split(',');
							if (this.isZybGray(arr22, 22) == false && this.isbGray(arr22, 22) == false) {
								this.normalColor(22)
							}
						} else if (con23 != "" && con22 == "") {
							arr23 = con23.split(',');
							if (this.isZybGray(arr23, 23) == false) {
								this.normalColor(22)
							}
						} else {
							arr23 = con23.split(',');
							arr22 = con22.split(',');
							if (this.isZybGray(arr23, 23) == false && this.isZybGray(arr22, 22) == false) {
								this.normalColor(22)
							}
						}
						//如果11和22不是左右本牙灰,那么21就不灰了
						if (this.isZybGray(arr11, 11) == false && this.isZybGray(arr22, 22) == false) {
							this.normalColor(21);
						}
					} else if (num == 22) {
						let con21 = document.getElementById("a21").innerHTML;
						let con11 = document.getElementById("a11").innerHTML;
						let con24 = document.getElementById("a24").innerHTML;
						let con23 = document.getElementById("a23").innerHTML;
						let arr21 = [];
						let arr11 = [];
						let arr23 = [];
						let arr24 = [];
						//21
						if (con11 == "" && con21 == "") {
							this.normalColor(21);
						} else if (con11 == "" && con21 != "") {
							arr21 = con21.split(',');
							if (this.isZybGray(arr21, 21) == false && this.isbGray(arr21, 21) == false) {
								this.normalColor(21)
							}
						} else if (con11 != "" && con21 == "") {
							arr11 = con11.split(',');
							if (this.isZybGray(arr11, 11) == false) {
								this.normalColor(21)
							}
						} else {
							arr21 = con21.split(',');
							arr11 = con11.split(',');
							if (this.isZybGray(arr21, 21) == false && this.isZybGray(arr11, 11) == false) {
								this.normalColor(21)
							}
						}
						//23
						if (con24 == "" && con23 == "") {
							this.normalColor(22);
						} else if (con24 == "" && con23 != "") {
							arr23 = con23.split(',');
							if (this.isZybGray(arr23, 23) == false && this.isbGray(arr23, 23) == false) {
								this.normalColor(23)
							}
						} else if (con24 != "" && con23 == "") {
							arr24 = con24.split(',');
							if (this.isZybGray(arr24, 24) == false) {
								this.normalColor(23)
							}
						} else {
							arr23 = con23.split(',');
							arr24 = con24.split(',');
							if (this.isZybGray(arr23, 23) == false && this.isZybGray(arr24, 24) == false) {
								this.normalColor(23)
							}
						}
						//如果21和23不是左右本牙灰,那么22就不灰了
						if (this.isZybGray(arr21, 21) == false && this.isZybGray(arr23, 23) == false) {
							this.normalColor(22);
						}
					} else if (num == 23) {
						let con21 = document.getElementById("a21").innerHTML;
						let con22 = document.getElementById("a22").innerHTML;
						let con24 = document.getElementById("a24").innerHTML;
						let con25 = document.getElementById("a25").innerHTML;
						let arr21 = [];
						let arr22 = [];
						let arr24 = [];
						let arr25 = [];
						//21
						if (con21 == "" && con22 == "") {
							this.normalColor(22);
						} else if (con21 == "" && con22 != "") {
							arr22 = con22.split(',');
							if (this.isZybGray(arr22, 22) == false && this.isbGray(arr22, 22) == false) {
								this.normalColor(22)
							}
						} else if (con21 != "" && con22 == "") {
							arr21 = con21.split(',');
							if (this.isZybGray(arr21, 21) == false) {
								this.normalColor(22)
							}
						} else {
							arr21 = con21.split(',');
							arr22 = con22.split(',');
							if (this.isZybGray(arr21, 21) == false && this.isZybGray(arr22, 22) == false && this.isbGray(
									arr22, 22)) {
								this.normalColor(22)
							}
						}
						//23
						if (con25 == "" && con24 == "") {
							this.normalColor(24);
						} else if (con25 == "" && con24 != "") {
							arr24 = con24.split(',');
							if (this.isZybGray(arr24, 24) == false && this.isbGray(arr24, 24) == false) {
								this.normalColor(24)
							}
						} else if (con25 != "" && con24 == "") {
							arr25 = con25.split(',');
							if (this.isZybGray(arr25, 25) == false) {
								this.normalColor(24)
							}
						} else {
							arr25 = con25.split(',');
							arr24 = con24.split(',');
							if (this.isZybGray(arr25, 25) == false && this.isZybGray(arr24, 24) == false) {
								this.normalColor(25)
							}
						}
						//如果22和24不是左右本牙灰,那么23就不灰了
						if (this.isZybGray(arr24, 24) == false && this.isZybGray(arr22, 22) == false) {
							this.normalColor(23);
						}
					} else if (num == 24) {
						let con22 = document.getElementById("a22").innerHTML;
						let con23 = document.getElementById("a23").innerHTML;
						let con26 = document.getElementById("a26").innerHTML;
						let con25 = document.getElementById("a25").innerHTML;
						let arr22 = [];
						let arr23 = [];
						let arr25 = [];
						let arr26 = [];
						//21
						if (con22 == "" && con23 == "") {
							this.normalColor(23);
						} else if (con22 == "" && con23 != "") {
							arr23 = con23.split(',');
							if (this.isZybGray(arr23, 23) == false && this.isbGray(arr23, 23) == false) {
								this.normalColor(23)
							}
						} else if (con22 != "" && con23 == "") {
							arr22 = con22.split(',');
							if (this.isZybGray(arr22, 22) == false) {
								this.normalColor(23)
							}
						} else {
							arr23 = con23.split(',');
							arr22 = con22.split(',');
							if (this.isZybGray(arr23, 23) == false && this.isZybGray(arr22, 22) == false && this.isbGray(
									arr23, 23) == false) {
								this.normalColor(23)
							}
						}
						//23
						if (con26 == "" && con25 == "") {
							this.normalColor(25);
						} else if (con26 == "" && con25 != "") {
							arr25 = con25.split(',');
							if (this.isZybGray(arr25, 25) == false && this.isbGray(arr25, 25) == false) {
								this.normalColor(25)
							}
						} else if (con26 != "" && con25 == "") {
							arr26 = con26.split(',');
							if (this.isZybGray(arr26, 26) == false) {
								this.normalColor(25)
							}
						} else {
							arr25 = con25.split(',');
							arr26 = con26.split(',');
							if (this.isZybGray(arr25, 25) == false && this.isZybGray(arr26, 26) == false) {
								this.normalColor(25)
							}
						}
						//如果22和24不是左右本牙灰,那么23就不灰了
						if (this.isZybGray(arr23, 23) == false && this.isZybGray(arr25, 25) == false) {
							this.normalColor(24);
						}
					} else if (num == 25) {
						let con24 = document.getElementById("a24").innerHTML;
						let con23 = document.getElementById("a23").innerHTML;
						let con26 = document.getElementById("a26").innerHTML;
						let con27 = document.getElementById("a27").innerHTML;
						let arr24 = [];
						let arr23 = [];
						let arr27 = [];
						let arr26 = [];
						//21
						if (con23 == "" && con24 == "") {
							this.normalColor(24);
						} else if (con23 == "" && con24 != "") {
							arr24 = con24.split(',');
							if (this.isZybGray(arr24, 24) == false && this.isbGray(arr24, 24) == false) {
								this.normalColor(24)
							}
						} else if (con23 != "" && con24 == "") {
							arr23 = con23.split(',');
							if (this.isZybGray(arr23, 23) == false) {
								this.normalColor(24)
							}
						} else {
							arr23 = con23.split(',');
							arr24 = con24.split(',');
							if (this.isZybGray(arr23, 23) == false && this.isZybGray(arr24, 24) == false && this.isbGray(
									arr24, 24) == false) {
								this.normalColor(24)
							}
						}
						//26
						if (con27 == "" && con26 == "") {
							this.normalColor(26);
						} else if (con27 == "" && con26 != "") {
							arr26 = con26.split(',');
							if (this.isZybGray(arr26, 26) == false && this.isbGray(arr26, 26) == false) {
								this.normalColor(26)
							}
						} else if (con27 != "" && con26 == "") {
							arr27 = con27.split(',');
							if (this.isZybGray(arr27, 27) == false) {
								this.normalColor(26)
							}
						} else {
							arr27 = con27.split(',');
							arr26 = con26.split(',');
							if (this.isZybGray(arr27, 27) == false && this.isZybGray(arr26, 26) == false && this.isbGray(
									arr26, 26) == false) {
								this.normalColor(26)
							}
						}
						//如果26和24不是左右本牙灰,那么25就不灰了
						if (this.isZybGray(arr26, 26) == false && this.isZybGray(arr24, 24) == false) {
							this.normalColor(25);
						}
					} else if (num == 26) {
						let con24 = document.getElementById("a24").innerHTML;
						let con25 = document.getElementById("a25").innerHTML;
						let con28 = document.getElementById("a28").innerHTML;
						let con27 = document.getElementById("a27").innerHTML;
						let arr24 = [];
						let arr25 = [];
						let arr27 = [];
						let arr28 = [];
						//21
						if (con24 == "" && con25 == "") {
							this.normalColor(25);
						} else if (con24 == "" && con25 != "") {
							arr25 = con25.split(',');
							if (this.isZybGray(arr25, 25) == false && this.isbGray(arr25, 25) == false) {
								this.normalColor(25)
							}
						} else if (con24 != "" && con25 == "") {
							arr24 = con24.split(',');
							if (this.isZybGray(arr24, 24) == false) {
								this.normalColor(25)
							}
						} else {
							arr25 = con25.split(',');
							arr24 = con24.split(',');
							if (this.isZybGray(arr24, 24) == false && this.isZybGray(arr25, 25) == false && this.isbGray(
									arr25, 25) == false) {
								this.normalColor(25)
							}
						}
						//27
						if (con28 == "" && con27 == "") {
							this.normalColor(27);
						} else if (con28 == "" && con27 != "") {
							arr27 = con27.split(',');
							if (this.isZybGray(arr27, 27) == false && this.isbGray(arr27, 27) == false) {
								this.normalColor(27)
							}
						} else if (con28 != "" && con27 == "") {
							arr28 = con28.split(',');
							if (this.isZybGray(arr28, 28) == false) {
								this.normalColor(27)
							}
						} else {
							arr27 = con27.split(',');
							arr28 = con28.split(',');
							if (this.isZybGray(arr28, 28) == false && this.isZybGray(arr27, 27) == false && this.isbGray(
									arr27, 27) == false) {
								this.normalColor(27)
							}
						}
						//如果25和27不是左右本牙灰,那么26就不灰了
						if (this.isZybGray(arr25, 25) == false && this.isZybGray(arr27, 27) == false) {
							this.normalColor(26);
						}
					} else if (num == 27) {
						let con26 = document.getElementById("a26").innerHTML;
						let con25 = document.getElementById("a25").innerHTML;
						let con28 = document.getElementById("a28").innerHTML;

						let arr26 = [];
						let arr25 = [];
						let arr28 = [];
						//26
						if (con25 == "" && con26 == "") {
							this.normalColor(26);
						} else if (con25 == "" && con26 != "") {
							arr26 = con26.split(',');
							if (this.isZybGray(arr26, 26) == false && this.isbGray(arr26, 26) == false) {
								this.normalColor(26)
							}
						} else if (con25 != "" && con26 == "") {
							arr25 = con25.split(',');
							if (this.isZybGray(arr25, 25) == false) {
								this.normalColor(26)
							}
						} else {
							arr25 = con25.split(',');
							arr26 = con26.split(',');
							if (this.isZybGray(arr25, 25) == false && this.isZybGray(arr26, 26) == false && this.isbGray(
									arr26, 26) == false) {
								this.normalColor(26)
							}
						}
						//28
						if (con28 == "") {
							this.normalColor(28);
						} else if (con28 != "") {
							arr28 = con28.split(',');
							if (this.isZybGray(arr28, 28) == false && this.isbGray(arr28, 28)) {
								this.normalColor(28)
							}
						}
						//如果26和28不是左右本牙灰,那么27就不灰了
						if (this.isZybGray(arr26, 26) == false && this.isZybGray(arr28, 28) == false) {
							this.normalColor(27);
						}
					} else if (num == 28) {
						let con26 = document.getElementById("a26").innerHTML;
						let con27 = document.getElementById("a27").innerHTML;

						let arr26 = [];
						let arr27 = [];
						//26
						if (con26 == "" && con27 == "") {
							this.normalColor(27);
						} else if (con26 == "" && con27 != "") {
							arr27 = con27.split(',');
							if (this.isZybGray(arr27, 27) == false && this.isbGray(arr27, 27) == false) {
								this.normalColor(27)
							}
						} else if (con26 != "" && con27 == "") {
							arr26 = con26.split(',');
							if (this.isZybGray(arr26, 26) == false) {
								this.normalColor(27)
							}
						} else {
							arr27 = con27.split(',');
							arr26 = con26.split(',');
							if (this.isZybGray(arr26, 26) == false && this.isZybGray(arr27, 27) == false && this.isbGray(
									arr27, 27) == false) {
								this.normalColor(27)
							}
						}

						//如果27不是左右本牙灰,那么28就不灰了
						if (this.isZybGray(arr27, 27) == false) {
							this.normalColor(28);
						}
					} else if (num == 31) {
						console.log(31);
						let con42 = document.getElementById("a42").innerHTML;
						console.log(con42);
						let con41 = document.getElementById("a41").innerHTML;
						let con32 = document.getElementById("a32").innerHTML;
						let con33 = document.getElementById("a33").innerHTML;
						let arr42 = [];
						let arr41 = [];
						let arr33 = [];
						let arr32 = [];
						//11
						if (con42 == "" && con41 == "") {
							console.log(41);
							this.normalColor(41);
						} else if (con42 == "" && con41 != "") {
							arr41 = con41.split(',');
							if (this.isZybGray(arr41, 41) == false && this.isbGray(arr41, 41) == false) {
								console.log(41);
								this.normalColor(41)
							}
						} else if (con42 != "" && con41 == "") {
							arr42 = con42.split(',');
							if (this.isZybGray(arr42, 42) == false) {
								console.log(41);
								this.normalColor(41)
							}
						} else {
							arr41 = con41.split(',');
							arr42 = con42.split(',');
							if (this.isZybGray(arr42, 42) == false && this.isZybGray(arr41, 41) == false && this.isbGray(
									arr41, 41)) {
								console.log(41);
								this.normalColor(41)
							}
						}
						//2
						if (con33 == "" && con32 == "") {
							this.normalColor(32);
						} else if (con33 == "" && con32 != "") {
							arr32 = con32.split(',');
							if (this.isZybGray(arr32, 32) == false && this.isbGray(arr32, 32) == false) {
								this.normalColor(32)
							}
						} else if (con33 != "" && con32 == "") {
							arr33 = con33.split(',');
							if (this.isZybGray(arr33, 33) == false) {
								this.normalColor(32)
							}
						} else {
							arr33 = con23.split(',');
							arr32 = con22.split(',');
							if (this.isZybGray(arr33, 33) == false && this.isZybGray(arr32, 32) == false && this.isbGray(
									arr32, 32)) {
								this.normalColor(32)
							}
						}
						//如果41和32不是左右本牙灰,那么31就不灰了
						if (this.isZybGray(arr41, 41) == false && this.isZybGray(arr32, 32) == false) {
							this.normalColor(31);
						}
					} else if (num == 32) {
						let con33 = document.getElementById("a33").innerHTML;
						let con34 = document.getElementById("a34").innerHTML;
						let con31 = document.getElementById("a31").innerHTML;
						let con41 = document.getElementById("a41").innerHTML;
						let arr33 = [];
						let arr34 = [];
						let arr31 = [];
						let arr41 = [];

						//31
						if (con41 == "" && con31 == "") {
							this.normalColor(31);
						} else if (con41 == "" && con31 != "") {
							arr31 = con31.split(',');
							if (this.isZybGray(arr31, 31) == false && this.isbGray(arr31, 31) == false) {
								this.normalColor(31)
							}
						} else if (con41 != "" && con31 == "") {
							arr41 = con41.split(',');
							if (this.isZybGray(arr41, 41) == false) {
								this.normalColor(31)
							}
						} else {
							arr41 = con41.split(',');
							arr31 = con31.split(',');
							if (this.isZybGray(arr41, 41) == false && this.isZybGray(arr31, 31) == false && this.isbGray(
									arr31, 31)) {
								this.normalColor(31)
							}
						}
						//33
						if (con34 == "" && con33 == "") {
							this.normalColor(33);
						} else if (con34 == "" && con33 != "") {
							arr33 = con33.split(',');
							if (this.isZybGray(arr33, 33) == false && this.isbGray(arr33, 33) == false) {
								this.normalColor(33)
							}
						} else if (con34 != "" && con33 == "") {
							arr34 = con34.split(',');
							if (this.isZybGray(arr34, 34) == false) {
								this.normalColor(33)
							}
						} else {
							arr33 = con33.split(',');
							arr34 = con34.split(',');
							if (this.isZybGray(arr34, 34) == false && this.isZybGray(arr33, 33) == false && this.isbGray(
									arr33, 33)) {
								this.normalColor(33)
							}
						}
						//如果31和33不是左右本牙灰,那么32就不灰了
						if (this.isZybGray(arr31, 31) == false && this.isZybGray(arr33, 33) == false) {
							this.normalColor(32);
						}
					} else if (num == 33) {
						let con31 = document.getElementById("a31").innerHTML;
						let con32 = document.getElementById("a32").innerHTML;
						let con34 = document.getElementById("a34").innerHTML;
						let con35 = document.getElementById("a35").innerHTML;
						let arr31 = [];
						let arr32 = [];
						let arr34 = [];
						let arr35 = [];

						//31
						if (con31 == "" && con32 == "") {
							this.normalColor(32);
						} else if (con31 == "" && con32 != "") {
							arr32 = con32.split(',');
							if (this.isZybGray(arr32, 32) == false && this.isbGray(arr32, 32) == false) {
								this.normalColor(32)
							}
						} else if (con31 != "" && con32 == "") {
							arr31 = con31.split(',');
							if (this.isZybGray(arr31, 31) == false) {
								this.normalColor(32)
							}
						} else {
							arr32 = con32.split(',');
							arr31 = con31.split(',');
							if (this.isZybGray(arr31, 31) == false && this.isZybGray(arr32, 32) == false && this.isbGray(
									arr32, 32)) {
								this.normalColor(32)
							}
						}
						//34
						if (con35 == "" && con34 == "") {
							this.normalColor(34);
						} else if (con35 == "" && con34 != "") {
							arr34 = con34.split(',');
							if (this.isZybGray(arr34, 34) == false && this.isbGray(arr34, 34) == false) {
								this.normalColor(34)
							}
						} else if (con35 != "" && con34 == "") {
							arr35 = con35.split(',');
							if (this.isZybGray(arr35, 35) == false) {
								this.normalColor(35)
							}
						} else {
							arr35 = con35.split(',');
							arr34 = con34.split(',');
							if (this.isZybGray(arr35, 35) == false && this.isZybGray(arr34, 34) == false && this.isbGray(
									arr34, 34)) {
								this.normalColor(34)
							}
						}
						//如果32和34不是左右本牙灰,那么33就不灰了
						if (this.isZybGray(arr32, 32) == false && this.isZybGray(arr34, 34) == false) {
							this.normalColor(33);
						}
					} else if (num == 34) {
						let con33 = document.getElementById("a33").innerHTML;
						let con32 = document.getElementById("a32").innerHTML;
						let con36 = document.getElementById("a36").innerHTML;
						let con35 = document.getElementById("a35").innerHTML;
						let arr33 = [];
						let arr32 = [];
						let arr36 = [];
						let arr35 = [];

						//31
						if (con32 == "" && con33 == "") {
							this.normalColor(33);
						} else if (con32 == "" && con33 != "") {
							arr33 = con33.split(',');
							if (this.isZybGray(arr33, 33) == false && this.isbGray(arr33, 33) == false) {
								this.normalColor(33)
							}
						} else if (con32 != "" && con33 == "") {
							arr32 = con32.split(',');
							if (this.isZybGray(arr32, 32) == false) {
								this.normalColor(33)
							}
						} else {
							arr32 = con32.split(',');
							arr33 = con33.split(',');
							if (this.isZybGray(arr32, 32) == false && this.isZybGray(arr33, 33) == false && this.isbGray(
									arr33, 33)) {
								this.normalColor(33)
							}
						}
						//34
						if (con36 == "" && con35 == "") {
							this.normalColor(35);
						} else if (con36 == "" && con35 != "") {
							arr35 = con35.split(',');
							if (this.isZybGray(arr35, 35) == false && this.isbGray(arr35, 35) == false) {
								this.normalColor(35)
							}
						} else if (con36 != "" && con35 == "") {
							arr36 = con36.split(',');
							if (this.isZybGray(arr36, 36) == false) {
								this.normalColor(36)
							}
						} else {
							arr35 = con35.split(',');
							arr36 = con36.split(',');
							if (this.isZybGray(arr36, 36) == false && this.isZybGray(arr35, 35) == false && this.isbGray(
									arr35, 35)) {
								this.normalColor(35)
							}
						}
						//如果33和35不是左右本牙灰,那么34就不灰了
						if (this.isZybGray(arr33, 33) == false && this.isZybGray(arr35, 35) == false) {
							this.normalColor(34);
						}
					} else if (num == 35) {
						let con33 = document.getElementById("a33").innerHTML;
						let con34 = document.getElementById("a34").innerHTML;
						let con36 = document.getElementById("a36").innerHTML;
						let con37 = document.getElementById("a37").innerHTML;
						let arr33 = [];
						let arr34 = [];
						let arr36 = [];
						let arr37 = [];

						//34
						if (con33 == "" && con34 == "") {
							this.normalColor(34);
						} else if (con33 == "" && con34 != "") {
							arr34 = con34.split(',');
							if (this.isZybGray(arr34, 34) == false && this.isbGray(arr34, 34) == false) {
								this.normalColor(34)
							}
						} else if (con33 != "" && con34 == "") {
							arr33 = con33.split(',');
							if (this.isZybGray(arr33, 33) == false) {
								this.normalColor(34)
							}
						} else {
							arr34 = con34.split(',');
							arr33 = con33.split(',');
							if (this.isZybGray(arr33, 33) == false && this.isZybGray(arr34, 34) == false && this.isbGray(
									arr34, 34)) {
								this.normalColor(34)
							}
						}
						//36
						if (con37 == "" && con36 == "") {
							this.normalColor(36);
						} else if (con37 == "" && con36 != "") {
							arr36 = con36.split(',');
							if (this.isZybGray(arr36, 36) == false && this.isbGray(arr36, 36) == false) {
								this.normalColor(36)
							}
						} else if (con37 != "" && con36 == "") {
							arr37 = con37.split(',');
							if (this.isZybGray(arr37, 37) == false) {
								this.normalColor(36)
							}
						} else {
							arr37 = con37.split(',');
							arr36 = con36.split(',');
							if (this.isZybGray(arr37, 37) == false && this.isZybGray(arr36, 36) == false && this.isbGray(
									arr36, 36)) {
								this.normalColor(36)
							}
						}
						//如果34和36不是左右本牙灰,那么35就不灰了
						if (this.isZybGray(arr34, 34) == false && this.isZybGray(arr36, 36) == false) {
							this.normalColor(35);
						}
					} else if (num == 36) {
						let con35 = document.getElementById("a35").innerHTML;
						let con34 = document.getElementById("a34").innerHTML;
						let con38 = document.getElementById("a38").innerHTML;
						let con37 = document.getElementById("a37").innerHTML;
						let arr35 = [];
						let arr34 = [];
						let arr38 = [];
						let arr37 = [];

						//34
						if (con34 == "" && con35 == "") {
							this.normalColor(35);
						} else if (con34 == "" && con35 != "") {
							arr35 = con35.split(',');
							if (this.isZybGray(arr35, 35) == false && this.isbGray(arr35, 35) == false) {
								this.normalColor(35)
							}
						} else if (con34 != "" && con35 == "") {
							arr34 = con34.split(',');
							if (this.isZybGray(arr34, 34) == false) {
								this.normalColor(35)
							}
						} else {
							arr34 = con34.split(',');
							arr35 = con35.split(',');
							if (this.isZybGray(arr34, 34) == false && this.isZybGray(arr35, 35) == false && this.isbGray(
									arr35, 35)) {
								this.normalColor(35)
							}
						}
						//37
						if (con38 == "" && con37 == "") {
							this.normalColor(37);
						} else if (con38 == "" && con37 != "") {
							arr37 = con37.split(',');
							if (this.isZybGray(arr37, 37) == false && this.isbGray(arr37, 37) == false) {
								this.normalColor(37)
							}
						} else if (con38 != "" && con37 == "") {
							arr38 = con38.split(',');
							if (this.isZybGray(arr38, 38) == false) {
								this.normalColor(37)
							}
						} else {
							arr37 = con37.split(',');
							arr38 = con38.split(',');
							if (this.isZybGray(arr38, 38) == false && this.isZybGray(arr37, 37) == false && this.isbGray(
									arr37, 37)) {
								this.normalColor(37)
							}
						}
						//如果35和37不是左右本牙灰,那么36就不灰了
						if (this.isZybGray(arr35, 35) == false && this.isZybGray(arr37, 37) == false) {
							this.normalColor(36);
						}
					} else if (num == 37) {
						let con35 = document.getElementById("a35").innerHTML;
						let con36 = document.getElementById("a36").innerHTML;
						let con38 = document.getElementById("a38").innerHTML;
						let arr35 = [];
						let arr36 = [];
						let arr38 = [];

						//36
						if (con35 == "" && con36 == "") {
							this.normalColor(36);
						} else if (con35 == "" && con36 != "") {
							arr36 = con36.split(',');
							if (this.isZybGray(arr36, 36) == false && this.isbGray(arr36, 36) == false) {
								this.normalColor(36)
							}
						} else if (con35 != "" && con36 == "") {
							arr35 = con35.split(',');
							if (this.isZybGray(arr35, 35) == false) {
								this.normalColor(36)
							}
						} else {
							arr36 = con36.split(',');
							arr35 = con35.split(',');
							if (this.isZybGray(arr35, 35) == false && this.isZybGray(arr36, 36) == false && this.isbGray(
									arr36, 36)) {
								this.normalColor(36)
							}
						}

						//如果36和38不是左右本牙灰,那么37就不灰了
						if (this.isZybGray(arr36, 36) == false && this.isZybGray(arr38, 38) == false) {
							this.normalColor(37);
						}
					} else if (num == 38) {
						let con36 = document.getElementById("a36").innerHTML;
						let con37 = document.getElementById("a37").innerHTML;
						let arr37 = [];
						let arr36 = [];

						//37
						if (con36 == "" && con37 == "") {
							this.normalColor(37);
						} else if (con36 == "" && con37 != "") {
							arr37 = con37.split(',');
							if (this.isZybGray(arr37, 37) == false && this.isbGray(arr37, 37) == false) {
								this.normalColor(37)
							}
						} else if (con36 != "" && con37 == "") {
							arr36 = con36.split(',');
							if (this.isZybGray(arr36, 36) == false) {
								this.normalColor(37)
							}
						} else {
							arr37 = con37.split(',');
							arr36 = con36.split(',');
							if (this.isZybGray(arr36, 36) == false && this.isZybGray(arr37, 37) == false && this.isbGray(
									arr37, 37)) {
								this.normalColor(37)
							}
						}
					} else if (num == 41) {
						console.log("我是41");
						let con31 = document.getElementById("a31").innerHTML;
						let con32 = document.getElementById("a32").innerHTML;
						let con42 = document.getElementById("a42").innerHTML;
						let con43 = document.getElementById("a43").innerHTML;
						let arr31 = [];
						let arr32 = [];
						let arr42 = [];
						let arr43 = [];

						//31
						if (con32 == "" && con31 == "") {
							this.normalColor(31);
						} else if (con32 == "" && con31 != "") {
							arr31 = con31.split(',');
							if (this.isZybGray(arr31, 31) == false && this.isbGray(arr31, 31) == false) {
								this.normalColor(31)
							}
						} else if (con32 != "" && con31 == "") {
							arr32 = con32.split(',');
							if (this.isZybGray(arr32, 32) == false) {
								this.normalColor(31)
							}
						} else {
							arr32 = con32.split(',');
							arr31 = con31.split(',');
							if (this.isZybGray(arr32, 32) == false && this.isZybGray(arr31, 31) == false && this.isbGray(
									arr31, 31)) {
								this.normalColor(31)
							}
						}
						//42
						if (con43 == "" && con42 == "") {
							console.log(42);
							this.normalColor(42);
						} else if (con43 == "" && con42 != "") {
							arr42 = con42.split(',');
							if (this.isZybGray(arr42, 42) == false && this.isbGray(arr42, 42) == false) {
								console.log(42);
								this.normalColor(42)
							}
						} else if (con43 != "" && con42 == "") {
							arr43 = con43.split(',');
							if (this.isZybGray(arr43, 43) == false) {
								console.log(42);
								this.normalColor(42)
							}
						} else {
							arr42 = con42.split(',');
							arr43 = con43.split(',');
							if (this.isZybGray(arr43, 43) == false && this.isZybGray(arr42, 42) == false && this.isbGray(
									arr42, 42)) {
								console.log(42);
								this.normalColor(42)
							}
						}
						//如果42和31不是左右本牙灰,那么41就不灰了
						if (this.isZybGray(arr42, 42) == false && this.isZybGray(arr31, 31) == false) {
							this.normalColor(41);
						}
					} else if (num == 42) {
						console.log("我是41");
						let con31 = document.getElementById("a31").innerHTML;
						let con41 = document.getElementById("a41").innerHTML;
						let con44 = document.getElementById("a44").innerHTML;
						let con43 = document.getElementById("a43").innerHTML;
						let arr31 = [];
						let arr41 = [];
						let arr43 = [];
						let arr44 = [];

						//43
						if (con44 == "" && con43 == "") {
							this.normalColor(43);
						} else if (con44 == "" && con43 != "") {
							arr43 = con43.split(',');
							if (this.isZybGray(arr43, 43) == false && this.isbGray(arr43, 43) == false) {
								this.normalColor(43)
							}
						} else if (con44 != "" && con43 == "") {
							arr44 = con44.split(',');
							if (this.isZybGray(arr44, 44) == false) {
								this.normalColor(43)
							}
						} else {
							arr43 = con43.split(',');
							arr44 = con44.split(',');
							if (this.isZybGray(arr44, 44) == false && this.isZybGray(arr43, 43) == false && this.isbGray(
									arr43, 43)) {
								this.normalColor(43)
							}
						}
						//41
						if (con31 == "" && con41 == "") {
							this.normalColor(41);
						} else if (con31 == "" && con41 != "") {
							arr41 = con41.split(',');
							if (this.isZybGray(arr41, 41) == false && this.isbGray(arr41, 41) == false) {
								this.normalColor(41)
							}
						} else if (con31 != "" && con41 == "") {
							arr31 = con31.split(',');
							if (this.isZybGray(arr31, 31) == false) {
								this.normalColor(31)
							}
						} else {
							arr41 = con41.split(',');
							arr31 = con31.split(',');
							if (this.isZybGray(arr31, 31) == false && this.isZybGray(arr41, 41) == false && this.isbGray(
									arr41, 41)) {
								this.normalColor(41)
							}
						}
						//如果43和41不是左右本牙灰,那么42就不灰了
						if (this.isZybGray(arr43, 43) == false && this.isZybGray(arr41, 41) == false) {
							this.normalColor(42);
						}
					} else if (num == 43) {
						console.log("我是41");
						let con42 = document.getElementById("a42").innerHTML;
						let con41 = document.getElementById("a41").innerHTML;
						let con44 = document.getElementById("a44").innerHTML;
						let con45 = document.getElementById("a45").innerHTML;
						let arr42 = [];
						let arr41 = [];
						let arr45 = [];
						let arr44 = [];

						//44
						if (con45 == "" && con44 == "") {
							this.normalColor(44);
						} else if (con45 == "" && con44 != "") {
							arr44 = con44.split(',');
							if (this.isZybGray(arr44, 44) == false && this.isbGray(arr44, 44) == false) {
								this.normalColor(44)
							}
						} else if (con45 != "" && con44 == "") {
							arr45 = con45.split(',');
							if (this.isZybGray(arr45, 45) == false) {
								this.normalColor(44)
							}
						} else {
							arr45 = con45.split(',');
							arr44 = con44.split(',');
							if (this.isZybGray(arr45, 45) == false && this.isZybGray(arr44, 44) == false && this.isbGray(
									arr44, 44)) {
								this.normalColor(44)
							}
						}
						//42
						if (con41 == "" && con42 == "") {
							this.normalColor(42);
						} else if (con41 == "" && con42 != "") {
							arr42 = con42.split(',');
							if (this.isZybGray(arr42, 42) == false && this.isbGray(arr42, 42) == false) {
								this.normalColor(42)
							}
						} else if (con41 != "" && con42 == "") {
							arr41 = con41.split(',');
							if (this.isZybGray(arr41, 41) == false) {
								this.normalColor(42)
							}
						} else {
							arr41 = con41.split(',');
							arr42 = con42.split(',');
							if (this.isZybGray(arr41, 41) == false && this.isZybGray(arr42, 42) == false && this.isbGray(
									arr42, 42)) {
								this.normalColor(42)
							}
						}
						//如果44和42不是左右本牙灰,那么43就不灰了
						if (this.isZybGray(arr44, 44) == false && this.isZybGray(arr42, 42) == false) {
							this.normalColor(43);
						}
					} else if (num == 44) {
						let con42 = document.getElementById("a42").innerHTML;
						let con43 = document.getElementById("a43").innerHTML;
						let con46 = document.getElementById("a46").innerHTML;
						let con45 = document.getElementById("a45").innerHTML;
						let arr42 = [];
						let arr43 = [];
						let arr45 = [];
						let arr46 = [];

						//45
						if (con46 == "" && con45 == "") {
							this.normalColor(45);
						} else if (con46 == "" && con45 != "") {
							arr45 = con45.split(',');
							if (this.isZybGray(arr45, 45) == false && this.isbGray(arr45, 45) == false) {
								this.normalColor(45)
							}
						} else if (con46 != "" && con45 == "") {
							arr46 = con46.split(',');
							if (this.isZybGray(arr46, 46) == false) {
								this.normalColor(45)
							}
						} else {
							arr45 = con45.split(',');
							arr46 = con46.split(',');
							if (this.isZybGray(arr46, 46) == false && this.isZybGray(arr45, 45) == false && this.isbGray(
									arr45, 45)) {
								this.normalColor(45)
							}
						}
						//43
						if (con42 == "" && con43 == "") {
							this.normalColor(43);
						} else if (con42 == "" && con43 != "") {
							arr43 = con43.split(',');
							if (this.isZybGray(arr43, 43) == false && this.isbGray(arr43, 43) == false) {
								this.normalColor(43)
							}
						} else if (con42 != "" && con43 == "") {
							arr42 = con42.split(',');
							if (this.isZybGray(arr42, 42) == false) {
								this.normalColor(43)
							}
						} else {
							arr43 = con43.split(',');
							arr42 = con42.split(',');
							if (this.isZybGray(arr42, 42) == false && this.isZybGray(arr43, 43) == false && this.isbGray(
									arr43, 43)) {
								this.normalColor(43)
							}
						}
						//如果43和45不是左右本牙灰,那么44就不灰了
						if (this.isZybGray(arr43, 43) == false && this.isZybGray(arr45, 45) == false) {
							this.normalColor(44);
						}
					} else if (num == 45) {
						let con44 = document.getElementById("a44").innerHTML;
						let con43 = document.getElementById("a43").innerHTML;
						let con46 = document.getElementById("a46").innerHTML;
						let con47 = document.getElementById("a47").innerHTML;
						let arr44 = [];
						let arr43 = [];
						let arr47 = [];
						let arr46 = [];

						//46
						if (con47 == "" && con46 == "") {
							this.normalColor(46);
						} else if (con47 == "" && con46 != "") {
							arr46 = con46.split(',');
							if (this.isZybGray(arr46, 46) == false && this.isbGray(arr46, 46) == false) {
								this.normalColor(46)
							}
						} else if (con47 != "" && con46 == "") {
							arr47 = con47.split(',');
							if (this.isZybGray(arr47, 47) == false) {
								this.normalColor(46)
							}
						} else {
							arr47 = con47.split(',');
							arr46 = con46.split(',');
							if (this.isZybGray(arr47, 47) == false && this.isZybGray(arr46, 46) == false && this.isbGray(
									arr46, 46)) {
								this.normalColor(46)
							}
						}
						//44
						if (con43 == "" && con44 == "") {
							this.normalColor(44);
						} else if (con43 == "" && con44 != "") {
							arr44 = con44.split(',');
							if (this.isZybGray(arr44, 44) == false && this.isbGray(arr44, 44) == false) {
								this.normalColor(44)
							}
						} else if (con43 != "" && con44 == "") {
							arr43 = con43.split(',');
							if (this.isZybGray(arr43, 43) == false) {
								this.normalColor(44)
							}
						} else {
							arr43 = con43.split(',');
							arr44 = con44.split(',');
							if (this.isZybGray(arr43, 43) == false && this.isZybGray(arr44, 44) == false && this.isbGray(
									arr44, 44)) {
								this.normalColor(44)
							}
						}
						//如果44和46不是左右本牙灰,那么45就不灰了
						if (this.isZybGray(arr44, 44) == false && this.isZybGray(arr46, 46) == false) {
							this.normalColor(45);
						}
					} else if (num == 46) {
						let con44 = document.getElementById("a44").innerHTML;
						let con45 = document.getElementById("a45").innerHTML;
						let con48 = document.getElementById("a48").innerHTML;
						let con47 = document.getElementById("a47").innerHTML;
						let arr44 = [];
						let arr45 = [];
						let arr47 = [];
						let arr48 = [];

						//47
						if (con48 == "" && con47 == "") {
							this.normalColor(47);
						} else if (con48 == "" && con47 != "") {
							arr47 = con47.split(',');
							if (this.isZybGray(arr47, 47) == false && this.isbGray(arr47, 47) == false) {
								this.normalColor(47)
							}
						} else if (con48 != "" && con47 == "") {
							arr48 = con48.split(',');
							if (this.isZybGray(arr48, 48) == false) {
								this.normalColor(47)
							}
						} else {
							arr47 = con47.split(',');
							arr48 = con48.split(',');
							if (this.isZybGray(arr48, 48) == false && this.isZybGray(arr47, 47) == false && this.isbGray(
									arr47, 47)) {
								this.normalColor(47)
							}
						}
						//45
						if (con44 == "" && con45 == "") {
							this.normalColor(45);
						} else if (con44 == "" && con45 != "") {
							arr45 = con45.split(',');
							if (this.isZybGray(arr45, 45) == false && this.isbGray(arr45, 45) == false) {
								this.normalColor(45)
							}
						} else if (con44 != "" && con44 == "") {
							arr44 = con44.split(',');
							if (this.isZybGray(arr44, 45) == false) {
								this.normalColor(45)
							}
						} else {
							arr45 = con45.split(',');
							arr44 = con44.split(',');
							if (this.isZybGray(arr44, 44) == false && this.isZybGray(arr45, 45) == false && this.isbGray(
									arr45, 45)) {
								this.normalColor(45)
							}
						}
						//如果45和47不是左右本牙灰,那么46就不灰了
						if (this.isZybGray(arr45, 45) == false && this.isZybGray(arr47, 47) == false) {
							this.normalColor(46);
						}
					} else if (num == 47) {
						let con45 = document.getElementById("a45").innerHTML;
						let con46 = document.getElementById("a46").innerHTML;
						let con48 = document.getElementById("a48").innerHTML;
						let arr45 = [];
						let arr46 = [];
						let arr48 = [];

						//46
						if (con45 == "" && con46 == "") {
							this.normalColor(46);
						} else if (con45 == "" && con46 != "") {
							arr46 = con46.split(',');
							if (this.isZybGray(arr46, 46) == false && this.isbGray(arr46, 46) == false) {
								this.normalColor(46)
							}
						} else if (con45 != "" && con46 == "") {
							arr45 = con45.split(',');
							if (this.isZybGray(arr45, 45) == false) {
								this.normalColor(46)
							}
						} else {
							arr45 = con45.split(',');
							arr46 = con46.split(',');
							if (this.isZybGray(arr45, 45) == false && this.isZybGray(arr46, 46) == false && this.isbGray(
									arr46, 46)) {
								this.normalColor(46)
							}
						}
						// //45
						// if (con44 == "" && con45 == "") {
						// 	this.normalColor(45);
						// } else if (con44 == "" && con45 != "") {
						// 	arr45= con45.split(',');
						// 	if (this.isZybGray(arr45, 45) == false && this.isbGray(arr45, 45) == false) {
						// 		this.normalColor(45)
						// 	}
						// } else if (con44 != "" && con44== "") {
						// 	arr44= con44.split(',');
						// 	if (this.isZybGray(arr44, 45) == false) {
						// 		this.normalColor(45)
						// 	}
						// } else {
						// 	arr45= con45.split(',');
						// 	arr44= con44.split(',');
						// 	if (this.isZybGray(arr44, 44) == false && this.isZybGray(arr45, 45) == false&&this.isbGray(arr45,45)) {
						// 		this.normalColor(45)
						// 	}
						// }
						//如果48和46不是左右本牙灰,那么47就不灰了
						if (this.isZybGray(arr48, 48) == false && this.isZybGray(arr46, 46) == false) {
							this.normalColor(47);
						}
					} else if (num == 48) {
						let con47 = document.getElementById("a47").innerHTML;
						let con46 = document.getElementById("a46").innerHTML;
						let arr46 = [];
						let arr47 = [];

						//47
						if (con46 == "" && con47 == "") {
							this.normalColor(47);
						} else if (con46 == "" && con47 != "") {
							arr47 = con47.split(',');
							if (this.isZybGray(arr47, 47) == false && this.isbGray(arr47, 47) == false) {
								this.normalColor(47)
							}
						} else if (con46 != "" && con47 == "") {
							arr46 = con46.split(',');
							if (this.isZybGray(arr46, 46) == false) {
								this.normalColor(47)
							}
						} else {
							arr47 = con47.split(',');
							arr46 = con46.split(',');
							if (this.isZybGray(arr46, 46) == false && this.isZybGray(arr47, 47) == false && this.isbGray(
									arr47, 47)) {
								this.normalColor(47)
							}
						}

					}





				}
			},
			//处理11 21 31 41这些牙
			grayy(num) {
				if (num == 11) { //11 12 21都是灰色
					let oldClass1 = document.getElementsByClassName("ya11")[0].getAttribute("class");
					let oldClass2 = document.getElementsByClassName("ya11")[1].getAttribute("class");
					document.getElementsByClassName("ya11")[0].setAttribute("class",  "ya11 hei");
					document.getElementsByClassName("ya11")[1].setAttribute("class", oldClass2 + " hei");
					//禁用yayi对应的div的单击事件
					document.getElementsByClassName("ya11")[0].setAttribute("style", "pointer-events:none");
					document.getElementsByClassName("ya11")[1].setAttribute("style", "pointer-events:none")
					document.getElementsByClassName("ya11")[1].style.backgroundColor="#CCCCCC"
					//12
					let oldClass11 = document.getElementsByClassName("ya12")[0].getAttribute("class");
					let oldClass22 = document.getElementsByClassName("ya12")[1].getAttribute("class");
					document.getElementsByClassName("ya12")[0].setAttribute("class",  "ya12 hei");
					document.getElementsByClassName("ya12")[1].setAttribute("class", oldClass22 + " hei");
					//禁用yayi对应的div的单击事件
					document.getElementsByClassName("ya12")[0].setAttribute("style", "pointer-events:none");
					document.getElementsByClassName("ya12")[1].setAttribute("style", "pointer-events:none")
					document.getElementsByClassName("ya12")[1].style.backgroundColor="#CCCCCC"
					//21
					let oldClass111 = document.getElementsByClassName("ya21")[0].getAttribute("class");
					let oldClass222 = document.getElementsByClassName("ya21")[1].getAttribute("class");
					document.getElementsByClassName("ya21")[0].setAttribute("class",  "ya21 hei");
					document.getElementsByClassName("ya21")[1].setAttribute("class", oldClass222 + " hei");
					//禁用yayi对应的div的单击事件
					document.getElementsByClassName("ya21")[0].setAttribute("style", "pointer-events:none");
					document.getElementsByClassName("ya21")[1].setAttribute("style", "pointer-events:none")
				    document.getElementsByClassName("ya21")[1].style.backgroundColor="#CCCCCC"
				} else if (num == 21) { //21 22 11灰色
					let oldClass1 = document.getElementsByClassName("ya11")[0].getAttribute("class");
					let oldClass2 = document.getElementsByClassName("ya11")[1].getAttribute("class");
					document.getElementsByClassName("ya11")[0].setAttribute("class", "ya11 hei");
					document.getElementsByClassName("ya11")[1].setAttribute("class", oldClass2 + " hei");
					//禁用yayi对应的div的单击事件
					document.getElementsByClassName("ya11")[0].setAttribute("style", "pointer-events:none");
					document.getElementsByClassName("ya11")[1].setAttribute("style", "pointer-events:none")
					document.getElementsByClassName("ya11")[1].style.backgroundColor="#CCCCCC";
					//22
					let oldClass11 = document.getElementsByClassName("ya22")[0].getAttribute("class");
					let oldClass22 = document.getElementsByClassName("ya22")[1].getAttribute("class");
					document.getElementsByClassName("ya22")[0].setAttribute("class",  "ya22 hei");
					document.getElementsByClassName("ya22")[1].setAttribute("class", oldClass22 + " hei");
					//禁用yayi对应的div的单击事件
					document.getElementsByClassName("ya22")[0].setAttribute("style", "pointer-events:none");
					document.getElementsByClassName("ya22")[1].setAttribute("style", "pointer-events:none")
					document.getElementsByClassName("ya22")[1].style.backgroundColor="#CCCCCC";
					//21
					let oldClass111 = document.getElementsByClassName("ya21")[0].getAttribute("class");
					let oldClass222 = document.getElementsByClassName("ya21")[1].getAttribute("class");
					document.getElementsByClassName("ya21")[0].setAttribute("class", "ya21 hei");
					document.getElementsByClassName("ya21")[1].setAttribute("class", oldClass222 + " hei");
					//禁用yayi对应的div的单击事件
					document.getElementsByClassName("ya21")[0].setAttribute("style", "pointer-events:none");
					document.getElementsByClassName("ya21")[1].setAttribute("style", "pointer-events:none")
				   document.getElementsByClassName("ya21")[1].style.backgroundColor="#CCCCCC";
				} else if (num == 31) { //31 32 41
					//31
					let oldClass1 = document.getElementsByClassName("ya31")[0].getAttribute("class");
					let oldClass2 = document.getElementsByClassName("ya31")[1].getAttribute("class");
					document.getElementsByClassName("ya31")[0].setAttribute("class", oldClass1 + " hei");
					document.getElementsByClassName("ya31")[1].setAttribute("class", "ya31 hei");
					//document.getElementsByClassName("ya31")[1].setAttribute("class", oldClass2 + " hei");
					//禁用yayi对应的div的单击事件
					document.getElementsByClassName("ya31")[0].setAttribute("style", "pointer-events:none");
					document.getElementsByClassName("ya31")[1].setAttribute("style", "pointer-events:none")
					document.getElementsByClassName("ya31")[0].style.backgroundColor="#CCCCCC"
					//32
					let oldClass11 = document.getElementsByClassName("ya32")[0].getAttribute("class");
					let oldClass22 = document.getElementsByClassName("ya32")[1].getAttribute("class");
					document.getElementsByClassName("ya32")[0].setAttribute("class", oldClass11 + " hei");
					document.getElementsByClassName("ya32")[1].setAttribute("class", "ya32 hei");
					//禁用yayi对应的div的单击事件
					document.getElementsByClassName("ya32")[0].setAttribute("style", "pointer-events:none");
					document.getElementsByClassName("ya32")[1].setAttribute("style", "pointer-events:none")
					document.getElementsByClassName("ya32")[0].style.backgroundColor="#CCCCCC"
					//41
					let oldClass111 = document.getElementsByClassName("ya41")[0].getAttribute("class");
					let oldClass222 = document.getElementsByClassName("ya41")[1].getAttribute("class");
					document.getElementsByClassName("ya41")[0].setAttribute("class", oldClass111 + " hei");
					document.getElementsByClassName("ya41")[1].setAttribute("class","ya41 hei");
					//禁用yayi对应的div的单击事件
					document.getElementsByClassName("ya41")[0].setAttribute("style", "pointer-events:none");
					document.getElementsByClassName("ya41")[1].setAttribute("style", "pointer-events:none")
				    document.getElementsByClassName("ya41")[0].style.backgroundColor="#CCCCCC"
				} else if (num == 41) { //41 42 31
					//41
					let oldClass1 = document.getElementsByClassName("ya41")[0].getAttribute("class");
					let oldClass2 = document.getElementsByClassName("ya41")[1].getAttribute("class");
					document.getElementsByClassName("ya41")[0].setAttribute("class", oldClass1 + " hei");
					document.getElementsByClassName("ya41")[1].setAttribute("class", "ya41 hei");
					//禁用yayi对应的div的单击事件
					document.getElementsByClassName("ya41")[0].setAttribute("style", "pointer-events:none");
					document.getElementsByClassName("ya41")[1].setAttribute("style", "pointer-events:none")
					 document.getElementsByClassName("ya41")[0].style.backgroundColor="#CCCCCC"
					//42
					let oldClass11 = document.getElementsByClassName("ya42")[0].getAttribute("class");
					let oldClass22 = document.getElementsByClassName("ya42")[1].getAttribute("class");
					document.getElementsByClassName("ya42")[0].setAttribute("class", oldClass11 + " hei");
					document.getElementsByClassName("ya42")[1].setAttribute("class", "ya42 hei");
					//禁用yayi对应的div的单击事件
					document.getElementsByClassName("ya42")[0].setAttribute("style", "pointer-events:none");
					document.getElementsByClassName("ya42")[1].setAttribute("style", "pointer-events:none")
					 document.getElementsByClassName("ya42")[0].style.backgroundColor="#CCCCCC"
					//31
					let oldClass111 = document.getElementsByClassName("ya31")[0].getAttribute("class");
					let oldClass222 = document.getElementsByClassName("ya31")[1].getAttribute("class");
					document.getElementsByClassName("ya31")[0].setAttribute("class", oldClass111 + " hei");
					document.getElementsByClassName("ya31")[1].setAttribute("class",  "ya31 hei");
					//禁用yayi对应的div的单击事件
					document.getElementsByClassName("ya31")[0].setAttribute("style", "pointer-events:none");
					document.getElementsByClassName("ya31")[1].setAttribute("style", "pointer-events:none")
				     document.getElementsByClassName("ya31")[0].style.backgroundColor="#CCCCCC"
				} else if (num == 17 || num == 27 || num == 37 || num == 47) {
					let a1 = 'ya' + num;
					let b = 'ya' + (num - 1);
					//a1
					let oldClass1 = document.getElementsByClassName(a1)[0].getAttribute("class");
					let oldClass2 = document.getElementsByClassName(a1)[1].getAttribute("class");
					document.getElementsByClassName(a1)[0].setAttribute("class", oldClass1 + " hei");
					document.getElementsByClassName(a1)[1].setAttribute("class", oldClass2 + " hei");
					//禁用yayi对应的div的单击事件
					document.getElementsByClassName(a1)[0].setAttribute("style", "pointer-events:none");
					document.getElementsByClassName(a1)[1].setAttribute("style", "pointer-events:none");
					
					//b
					let oldClass11 = document.getElementsByClassName(b)[0].getAttribute("class");
					let oldClass22 = document.getElementsByClassName(b)[1].getAttribute("class");
					document.getElementsByClassName(b)[0].setAttribute("class", oldClass11 + " hei");
					document.getElementsByClassName(b)[1].setAttribute("class", oldClass22 + " hei");
					//禁用yayi对应的div的单击事件
					document.getElementsByClassName(b)[0].setAttribute("style", "pointer-events:none");
					document.getElementsByClassName(b)[1].setAttribute("style", "pointer-events:none");
				    if(num == 17 || num == 27){
						document.getElementsByClassName(a1)[0].setAttribute("class", a1 + " hei");
						document.getElementsByClassName(b)[0].setAttribute("class",  b+" hei");
				    	document.getElementsByClassName(a1)[1].style.backgroundColor="#CCCCCC";
						document.getElementsByClassName(b)[1].style.backgroundColor="#CCCCCC"
				    }
					 if(num == 37 || num == 47){
						 document.getElementsByClassName(a1)[1].setAttribute("class",  a1+" hei");
						document.getElementsByClassName(b)[1].setAttribute("class",  b+" hei");
				    	document.getElementsByClassName(a1)[0].style.backgroundColor="#CCCCCC"
						document.getElementsByClassName(b)[0].style.backgroundColor="#CCCCCC"
				    }
				} else {
					let a1 = 'ya' + num;
					let b = 'ya' + (num - 1);
					let c = 'ya' + (num + 1);
					//a1
					let oldClass1 = document.getElementsByClassName(a1)[0].getAttribute("class");
					let oldClass2 = document.getElementsByClassName(a1)[1].getAttribute("class");
					document.getElementsByClassName(a1)[0].setAttribute("class", oldClass1 + " hei");
					document.getElementsByClassName(a1)[1].setAttribute("class", oldClass2 + " hei");
					//禁用yayi对应的div的单击事件
					document.getElementsByClassName(a1)[0].setAttribute("style", "pointer-events:none");
					document.getElementsByClassName(a1)[1].setAttribute("style", "pointer-events:none");
					//b
					let oldClass11 = document.getElementsByClassName(b)[0].getAttribute("class");
					let oldClass22 = document.getElementsByClassName(b)[1].getAttribute("class");
					document.getElementsByClassName(b)[0].setAttribute("class", oldClass11 + " hei");
					document.getElementsByClassName(b)[1].setAttribute("class", oldClass22 + " hei");
					//禁用yayi对应的div的单击事件
					document.getElementsByClassName(b)[0].setAttribute("style", "pointer-events:none");
					document.getElementsByClassName(b)[1].setAttribute("style", "pointer-events:none");
					//c
					let oldClass111 = document.getElementsByClassName(c)[0].getAttribute("class");
					let oldClass222 = document.getElementsByClassName(c)[1].getAttribute("class");
					document.getElementsByClassName(c)[0].setAttribute("class", oldClass111 + " hei");
					document.getElementsByClassName(c)[1].setAttribute("class", oldClass222 + " hei");
					//禁用yayi对应的div的单击事件
					document.getElementsByClassName(c)[0].setAttribute("style", "pointer-events:none");
					document.getElementsByClassName(c)[1].setAttribute("style", "pointer-events:none");
				   if(num>=12&&num<=26){
				   	document.getElementsByClassName(a1)[1].style.backgroundColor="#CCCCCC";
				   	document.getElementsByClassName(b)[1].style.backgroundColor="#CCCCCC";
					document.getElementsByClassName(c)[1].style.backgroundColor="#CCCCCC"
					document.getElementsByClassName(a1)[0].setAttribute("class", a1 + " hei")
					document.getElementsByClassName(b)[0].setAttribute("class", b + " hei")
					document.getElementsByClassName(c)[0].setAttribute("class", c + " hei")
				   }else if(num>=32 && num<=46){
				   	document.getElementsByClassName(a1)[0].style.backgroundColor="#CCCCCC"
				   	document.getElementsByClassName(b)[0].style.backgroundColor="#CCCCCC";
						document.getElementsByClassName(c)[0].style.backgroundColor="#CCCCCC";
						document.getElementsByClassName(a1)[1].setAttribute("class", a1 + " hei")
						document.getElementsByClassName(b)[1].setAttribute("class", b + " hei")
						document.getElementsByClassName(c)[1].setAttribute("class", c + " hei")
				   }
				}
			},

			/* 下面是32颗牙齿的操作 */
			getFind(id, yanum) {
				this.srcImg=require("../../assets/ysmimg/"+yanum+".png");
				this.dialogFormVisible = true;
				this.yaId = id;
				this.ya32Num = yanum;
				//获取id为变量id中的内容
				let english = document.getElementById(this.yaId).innerHTML;
				if (english != '') {
					let englishArr = english.split(',');
					let arr = [];
					let arrChinese = [];
					for (let i = 0; i < englishArr.length; i++) {
						arr.push(this.findContentEnglish.indexOf(englishArr[i]));
					}
					for (let i = 0; i < arr.length; i++) {
						arrChinese.push(this.findContentChinese[arr[i]]);
					}
					this.form.type = arrChinese;
				}

			},

			selItem() {

			},


			/* 下面是28颗牙的操作 */
			//左上添加和显示对号
			showDuiHao1(yanum, yan) {
				let dh = document.getElementById(yanum).getAttribute("class");
				if (dh.indexOf("duihao") == -1) { //没有对号背景图片
					this.zuoshanglist.push(yan);
					document.getElementById(yanum).setAttribute("class", dh + " duihao");
				} else { //有对号背景图片
					if (this.zuoshanglist.indexOf(yan) != -1) {
						this.zuoshanglist.splice(this.zuoshanglist.indexOf(yan), 1);
					}
					document.getElementById(yanum).setAttribute("class", yanum);
				}
			},
			//右上添加和显示对号
			showDuiHao2(yanum, yan) {
				let dh = document.getElementById(yanum).getAttribute("class");
				if (dh.indexOf("duihao") == -1) { //没有对号背景图片
					this.zuoshanglist.push(yan);
					document.getElementById(yanum).setAttribute("class", dh + " duihao");
				} else { //有对号背景图片
					if (this.zuoshanglist.indexOf(yan) != -1) {
						this.zuoshanglist.splice(this.zuoshanglist.indexOf(yan), 1);
					}
					document.getElementById(yanum).setAttribute("class", yanum);
				}
			},
			//左下添加和显示对号
			showDuiHao4(yanum, yan) {
				let dh = document.getElementById(yanum).getAttribute("class");
				if (dh.indexOf("duihao") == -1) { //没有对号背景图片
					this.zuoxialist.push(yan);
					document.getElementById(yanum).setAttribute("class", dh + " duihao");
				} else { //有对号背景图片
					if (this.zuoxialist.indexOf(yan) != -1) {
						this.zuoxialist.splice(this.zuoxialist.indexOf(yan), 1);
					}
					document.getElementById(yanum).setAttribute("class", yanum);
				}
			},
			//右下添加和显示对号
			showDuiHao3(yanum, yan) {
				let dh = document.getElementById(yanum).getAttribute("class");
				if (dh.indexOf("duihao") == -1) { //没有对号背景图片
					this.youxialist.push(yan);
					document.getElementById(yanum).setAttribute("class", dh + " duihao");
				} else { //有对号背景图片
					if (this.youxialist.indexOf(yan) != -1) {
						this.youxialist.splice(this.youxialist.indexOf(yan), 1);
					}
					document.getElementById(yanum).setAttribute("class", yanum);
				}
			},
			//当单击上下颌,上颌,下颌时候清空四个数组中的元素
			clearArray() {
				this.zuoshanglist = [];
				this.zuoxialist = [];
				this.youshanglist = [];
				this.youxialist = [];
			},
			//去掉所有的对号
			clearDuiHao() {
				let rows = document.getElementsByClassName("select-row1");
				let row1divs = rows[0].getElementsByTagName("div");
				let row2divs = rows[1].getElementsByTagName("div");
				for (let i = 0; i < row1divs.length; i++) {
					let className = row1divs[i].getAttribute("class");
					let newName = className.substring(0, 4);
					row1divs[i].setAttribute("class", newName)
				}
				for (let i = 0; i < row2divs.length; i++) {
					let className = row2divs[i].getAttribute("class");
					let newName = className.substring(0, 4);
					row2divs[i].setAttribute("class", newName)
				}

			},

			// 上下双颌,上颌 下颌选中发生的变化
			selectHe(e) {
				this.clearDuiHao();
				if (e == 2) { //上颌 上颌能用 下颌不能用
					//1.下颌不能用
					//牙齿变灰色
					document.getElementsByClassName("xh")[0].setAttribute("id", "hei");
					document.getElementsByClassName("xh")[1].setAttribute("id", "hei");
					//禁用yayi对应的div的单击事件
					document.getElementsByClassName("xh")[0].setAttribute("style", "pointer-events:none");
					document.getElementsByClassName("xh")[1].setAttribute("style", "pointer-events:none")
					//2.上颌可以用
					document.getElementsByClassName("sh")[0].removeAttribute("id");
					document.getElementsByClassName("sh")[0].removeAttribute("style");
					document.getElementsByClassName("sh")[1].removeAttribute("id");
					document.getElementsByClassName("sh")[1].removeAttribute("style");
					for(var i=31;i<=37;i++){
						document.getElementsByClassName("ya"+i)[0].style.backgroundColor="#CCCCCC"
					}
					for(var i=41;i<=47;i++){
						document.getElementsByClassName("ya"+i)[0].style.backgroundColor="#CCCCCC"
					}
					for(var i=11;i<=17;i++){
						document.getElementsByClassName("ya"+i)[1].removeAttribute("style");
					}
					for(var i=21;i<=27;i++){
						document.getElementsByClassName("ya"+i)[1].removeAttribute("style");
					}
					//显示其它牙列是否治疗过
					this.isVisiable = true;
					
				} else if (e == 3) { //选中下颌,上颌禁用变黑,下颌可以用
					//1.上颌不能用
					//牙齿变灰色
					document.getElementsByClassName("sh")[0].setAttribute("id", "hei");
					document.getElementsByClassName("sh")[1].setAttribute("id", "hei");
					//禁用yayi对应的div的单击事件
					document.getElementsByClassName("sh")[0].setAttribute("style", "pointer-events:none");
					document.getElementsByClassName("sh")[1].setAttribute("style", "pointer-events:none")
					//2.下颌可以用
					document.getElementsByClassName("xh")[0].removeAttribute("id");
					document.getElementsByClassName("xh")[0].removeAttribute("style");
					document.getElementsByClassName("xh")[1].removeAttribute("id");
					document.getElementsByClassName("xh")[1].removeAttribute("style");
					for(var i=31;i<=37;i++){
						document.getElementsByClassName("ya"+i)[0].removeAttribute("style");
					}
					for(var i=41;i<=47;i++){
						document.getElementsByClassName("ya"+i)[0].removeAttribute("style");
					}
					for(var i=11;i<=17;i++){
							document.getElementsByClassName("ya"+i)[1].style.backgroundColor="#CCCCCC"
					}
					for(var i=21;i<=27;i++){
							document.getElementsByClassName("ya"+i)[1].style.backgroundColor="#CCCCCC"
					}
					//显示其它牙列是否治疗过
					this.isVisiable = true;
					
				} else { //上下颌
					//2.上颌可以用
					document.getElementsByClassName("sh")[0].removeAttribute("id");
					document.getElementsByClassName("sh")[0].removeAttribute("style");
					document.getElementsByClassName("sh")[1].removeAttribute("id");
					document.getElementsByClassName("sh")[1].removeAttribute("style");
					//2.下颌可以用
					document.getElementsByClassName("xh")[0].removeAttribute("id");
					document.getElementsByClassName("xh")[0].removeAttribute("style");
					document.getElementsByClassName("xh")[1].removeAttribute("id");
					document.getElementsByClassName("xh")[1].removeAttribute("style");
					for(var i=31;i<=37;i++){
						document.getElementsByClassName("ya"+i)[0].removeAttribute("style");
					}
					for(var i=41;i<=47;i++){
						document.getElementsByClassName("ya"+i)[0].removeAttribute("style");
					}
					for(var i=11;i<=17;i++){
							document.getElementsByClassName("ya"+i)[1].removeAttribute("style");
					}
					for(var i=21;i<=27;i++){
							document.getElementsByClassName("ya"+i)[1].removeAttribute("style");
					}
					//隐藏其它牙列是否治疗过
					this.isVisiable = false;
					this.zradio = ""
				}
			},
			//下一步
			nextStepOrder() {
			
				//1.每颗牙齿的医学检查
				let jiancha = [];
				let srs = document.getElementsByClassName("container-table")[0].getElementsByClassName("select-row");
				let strs0 = srs[0].getElementsByTagName("div");
				let strs1 = srs[1].getElementsByTagName("div");
				for (let i = 0; i < strs0.length; i++) {
					let con = strs0[i].innerHTML;
					if (con != "") {
						let yahao = strs0[i].getAttribute("id");
						let obj = {
							"yahao": parseInt(yahao.substring(1, 3)),
							"qingkuang": con
						};
						jiancha.push(obj);
					}

				}
				for (let i = 0; i < strs1.length; i++) {
					let con = strs1[i].innerHTML;
					if (con != "") {
						let yahao = strs1[i].getAttribute("id");
						let obj = {
							"yahao": parseInt(yahao.substring(1, 3)),
							"qingkuang": con
						};
						jiancha.push(obj);
					}
				}
				//2.哪些牙齿需要移动
				let yidong = [];
				let sts = document.getElementsByClassName("selection-table")[0].getElementsByClassName("select-row");
				let sts0 = sts[0].getElementsByTagName("div");
				let sts1 = sts[1].getElementsByTagName("div");
				for (let i = 0; i < sts0.length; i++) {
					let className = sts0[i].getAttribute("class");
					if (className.indexOf("duihao") != -1) {
						let n = className.substring(2, 4);
						yidong.push(parseInt(n));
					}
				}
				for (let i = 0; i < sts1.length; i++) {
					let className = sts1[i].getAttribute("class");
					if (className.indexOf("duihao") != -1) {
						let n = className.substring(2, 4);
						yidong.push(parseInt(n));
					}
				}
				//3.哪些牙不能移动
				let noyidong = [];
				let nosts = document.getElementsByClassName("selection-table")[0].getElementsByClassName("select-row");
				let nsts0 = nosts[0].getElementsByTagName("div");
				let nsts1 = nosts[1].getElementsByTagName("div");
				for (let i = 0; i < nsts0.length; i++) {
					let className = nsts0[i].getAttribute("class");
					if (className.indexOf("hei") != -1) {
						let n = className.substring(0, 4);
						noyidong.push(n);
					}
				}
				console.log(noyidong);
				//3.封装对象,传向后台
				this.yaobj.yalie = this.radio;
				this.yaobj.zhiguo = this.zradio;
				this.yaobj.jiancha = jiancha;
				this.yaobj.yidong = yidong;
				this.yaobj.noyidong = noyidong;
				this.yaobj.shiyongxiang=this.checkList;
				this.yaobj.shuoming=this.desc;
				console.log(this.yaobj);

				this.$axios.post("/client/order/addYsmPlan", JSON.stringify(this.yaobj), {
					headers: {
						"Content-type": "application/json"
					}
				}).then(res => {
					if (res.data.code == 200) {
						//this.$message.error("添加成功");
						this.$store.commit("setYsmStepIndex", 3);
						this.$router.push("/ysmmain/ysmorder/ysmorderthree");
					}

				}).catch(err => {
					this.$alert("三生三世");
					//this.$message.error("添加失败");
				})
			},
			//返回
			back(){
				this.$store.commit("setYsmStepIndex",1);
				this.$router.push("/ysmmain/ysmorder/ysmorderone");
			},

		},
		mounted: function() {

			this.$store.commit("setYsmStepIndex", 2);
			document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
			document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
			document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
			document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3")	
			//设置返回的路径
			this.$store.commit("setBackLocation", "/ysmmain/ysmorder/ysmorderone");
		},
		created: function() {
			//如果是编辑订单要获取oid的值
			let oid = sessionStorage.getItem("oid");
			console.log(oid);
			if (oid != "undefined" && oid != '' && oid != null && oid != undefined) {
				this.yaobj.oid = oid;
				//根据oid查询订单信息
				this.$axios.get("/client/order/getOrderInfo", {
					params: {
						oid: this.yaobj.oid
					}
				}).then(res => {
					console.log(res.data);
					let orderInfo = res.data.data.plan;
					console.log(orderInfo);
					this.checkList=orderInfo.shiyongxiang;
					console.log(this.checkList);
					this.desc=orderInfo.shuoming;
					let jiancha = orderInfo.jiancha;
					let yidong = orderInfo.yidong;
					let yalie = orderInfo.yalie;
					let zhiguo = orderInfo.zhiguo;
					let noyidong = orderInfo.noyidong;
					if (yalie != null && yalie != "") {
						this.radio = yalie;
						if (this.radio == 2) {
							this.isVisiable = true;
							//1.下颌不能用
							//牙齿变灰色
							document.getElementsByClassName("xh")[0].setAttribute("id", "hei");
							document.getElementsByClassName("xh")[1].setAttribute("id", "hei");
							//禁用yayi对应的div的单击事件
							document.getElementsByClassName("xh")[0].setAttribute("style",
								"pointer-events:none");
							document.getElementsByClassName("xh")[1].setAttribute("style",
								"pointer-events:none")
							for(var i=31;i<=37;i++){
								document.getElementsByClassName("ya"+i)[0].style.backgroundColor="#CCCCCC"
							}
							for(var i=41;i<=47;i++){
								document.getElementsByClassName("ya"+i)[0].style.backgroundColor="#CCCCCC"
							}
							for(var i=11;i<=17;i++){
								document.getElementsByClassName("ya"+i)[1].removeAttribute("style");
							}
							for(var i=21;i<=27;i++){
								document.getElementsByClassName("ya"+i)[1].removeAttribute("style");
							}
							
							//2.上颌可以用
							document.getElementsByClassName("sh")[0].removeAttribute("id");
							document.getElementsByClassName("sh")[0].removeAttribute("style");
							document.getElementsByClassName("sh")[1].removeAttribute("id");
							document.getElementsByClassName("sh")[1].removeAttribute("style");
						}
						if (this.radio == 3) {
							this.isVisiable = true;
							//1.上颌不能用
							//牙齿变灰色
							document.getElementsByClassName("sh")[0].setAttribute("id", "hei");
							document.getElementsByClassName("sh")[1].setAttribute("id", "hei");
							//禁用yayi对应的div的单击事件
							document.getElementsByClassName("sh")[0].setAttribute("style",
								"pointer-events:none");
							document.getElementsByClassName("sh")[1].setAttribute("style",
								"pointer-events:none")
							//2.下颌可以用
							document.getElementsByClassName("xh")[0].removeAttribute("id");
							document.getElementsByClassName("xh")[0].removeAttribute("style");
							document.getElementsByClassName("xh")[1].removeAttribute("id");
							document.getElementsByClassName("xh")[1].removeAttribute("style");
							for(var i=31;i<=37;i++){
								document.getElementsByClassName("ya"+i)[0].removeAttribute("style");
							}
							for(var i=41;i<=47;i++){
								document.getElementsByClassName("ya"+i)[0].removeAttribute("style");
							}
							for(var i=11;i<=17;i++){
									document.getElementsByClassName("ya"+i)[1].style.backgroundColor="#CCCCCC"
							}
							for(var i=21;i<=27;i++){
									document.getElementsByClassName("ya"+i)[1].style.backgroundColor="#CCCCCC"
							}
						}
					}
					//是否治疗过
					if (zhiguo != null && zhiguo != "") {
						this.zradio = zhiguo
					}
					//检查
					if (jiancha != null) {
						for (let i = 0; i < jiancha.length; i++) {
							let id = jiancha[i].yahao;
							let con = jiancha[i].qingkuang;
							document.getElementById('a' + id).innerHTML = con;
						}
					}
					//移动
					if (yidong != null) {
						for (let i = 0; i < yidong.length; i++) {
							let id = yidong[i];
							console.log(id);
							let dh = document.getElementById("ya" + id).getAttribute("class");
							console.log(dh)
							document.getElementById("ya" + id).setAttribute("class", dh + " duihao");
						}
					}
					//变灰色
					//哪些牙齿变成灰色
					if (noyidong != null && noyidong.length > 0) {
						let noyd = noyidong;
						for (let i = 0; i < noyd.length; i++) {
							let id = noyd[i].substring(1,5);//"ya14"
							console.log(id);
							console.log(document.getElementsByClassName(id)[0].getAttribute("class"));
							let oldClass1 = document.getElementsByClassName(id)[0].getAttribute("class");
							let oldClass2 = document.getElementsByClassName(id)[1].getAttribute("class");
							document.getElementsByClassName(id)[0].setAttribute("class", oldClass1 + " hei");
							document.getElementsByClassName(id)[1].setAttribute("class", oldClass2 + " hei");
							//禁用yayi对应的div的单击事件
							document.getElementsByClassName(id)[0].setAttribute("style",
							"pointer-events:none");
							document.getElementsByClassName(id)[1].setAttribute("style", "pointer-events:none")
							// console.log(document.getElementsByClassName(id)[0].parent);
							 // document.getElementsByClassName(id)[0].parent.setAttribute("class","gray");
							 // document.getElementsByClassName(id)[1].parent.setAttribute("class","gray");
							 let n=noyd[i].substring(3,5);
							 if(n>=11&&n<=27){
								 document.getElementsByClassName(id)[1].style.backgroundColor="#CCCCCC";
							 }else{
								  document.getElementsByClassName(id)[0].style.backgroundColor="#CCCCCC";
							 }
						}
					}

				}).catch(err => {
					console.log(err);
				});
			} else {
				this.$alert('请填写患者信息', '提示', {
					confirmButtonText: '确定',
					callback: action => {
						this.$router.push("/ysmmain/ysmorder/ysmorderone");
					}
				});

			}
		}

	}
</script>

<style scoped="scoped">
	.ysm-order-two {
		margin: 0px auto;
		width: 80%;
		display: flex;
		margin-top: 3.125rem;
		padding-bottom: 5.125rem;
		justify-content: space-around;

	}

	.left {
		/* display: flex;
		margin-right: 12.5rem;
		position: relative;
		left: 12.5rem;
		width: 25rem; */
		display: flex;
		width: 30rem;
		position: relative;
		justify-content: flex-start;
	}

	h3 {
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #af9f73;
		margin-bottom: 20px;
		font-size: 24px;
	}

	.infoimg {
		width: 5.625rem;
		height: 6.875rem;
	}

	.con {
		/* position: relative;
		left: 9.55rem; */
		width: 560px;
	}

	.right {
		/* border: 1px solid red; */
	}

	/* 32颗牙 */
	.medical-report-ctrl {
		width: 100%;
	}

	.jianchaitem {
		color: #999999;
		font-size: 12px;
	}

	table {
		border: 0;
		background-color: transparent;
		width: 100%;
		border-spacing: 0;
		border-collapse: collapse;
	}

	table.container-table {
		border: solid 4px #ccc;
	}

	/* 左L右R的背景颜色 */
	table.container-table td.label-column {
		text-align: left;
		padding: 5px;
		background-color: #ccc;
	}

	/* 右R */
	table.container-table td.label-column:first-child {
		padding-right: 10px !important;
	}

	/* 左L */
	table.container-table td.label-column:last-child {
		padding-left: 10px !important;
	}

	table td {
		text-align: center !important;
		vertical-align: middle !important;
		border: 0 !important;
		padding: 0 !important;
	}

	.medical-report-ctrl table.report-table tr.select-row td div {
		height: 24px;
		line-height: 1.5rem;
		border: solid 2px #ccc;
		font-size: 13px;
		text-align: center;
		padding-top: 3px;
		cursor: pointer;
	}

	table.report-table td div {
		border-top: 0 !important;
		border-bottom: 0 !important;
		min-width: 31px;
		white-space: nowrap;
	}

	.medical-report-ctrl table.report-table td:nth-child(1) div {
		border-left: 0 !important;
		min-width: 29px;
	}

	/* table.report-table中的第二行的tr下边框褐色 */
	.medical-report-ctrl table.report-table tr:nth-child(2) td {
		border-bottom: solid 2px #333 !important;
	}

	/* 序号行的td的背景颜色 */
	.medical-report-ctrl table.report-table tr.label-row td {
		height: 15px;
		background-color: #ccc;
	}

	/* 白色隔条 */
	td.label-column-space div {
		background-color: #fff;
		width: 0.1875rem;
		border-left: 0;
		border-right: 0;
	}

	/* 最后一个td的div */
	.medical-report-ctrl table.report-table td:nth-last-child(1) div {
		border-right: 0 !important;
		min-width: 29px;
	}

	/* 竖线 */
	.medical-report-ctrl table.report-table tr.label-row td:nth-child(9) {
		border-left: solid 2px #333 !important;
	}

	/* =======================28颗牙齿============================== */
	tr {
		display: table-row;
	}

	.teeth-selection-ctrl {
		background-color: white;

	}

	.teeth-selection-ctrl table.container-table {
		border: 0;
	}

	.teeth-selection-ctrl table.selection-table {
		border: solid 1px #ccc;
		border-left-width: 2px !important;
		border-right-width: 2px !important;
	}

	.teeth-selection-ctrl table td {
		text-align: center !important;
		vertical-align: middle !important;
		background-color: #ccc;
		padding: 0 !important;

	}

	.teeth-selection-ctrl table.container-table th {
		font-size: 12px;
		color: #999;
	}

	th {
		font-weight: bold;
	}

	.teeth-selection-ctrl table.selection-table tr.select-row td:first-child div {
		border-left-color: #ccc !important;
	}

	.teeth-selection-ctrl table.selection-table tr.select-row td div {
		height: 28px;
		font-size: 22px;
		line-height: 25px;
		font-weight: bold;
		text-align: center;
		vertical-align: middle;
		border-right-color: #fff !important;
		border-left-color: #fff !important;
		color: #fff;
		background-color: #CCCCCC;
	}

	.teeth-selection-ctrl table.selection-table td div {
		min-width: 41px;
		cursor: pointer;
		border: solid 1px #ccc;

	}

	.teeth-selection-ctrl table.selection-table td * {
		box-sizing: content-box;
	}

	.teeth-selection-ctrl table.selection-table tr.teeth-row td div {
		height: 37px;
		font-size: 13px;
		text-align: center;
		background-color: #fff;
	}

	.teeth-selection-ctrl table.selection-table tr:nth-child(2) {
		border-bottom: solid 2px #CCCCCC !important;
	}

	.teeth-selection-ctrl table.selection-table td:nth-child(7) div {
		border-right-width: 3px !important;
	}

	.teeth-selection-ctrl table.selection-table td:nth-last-child(1) div {
		border-right: 0 !important;
		min-width: 38px;
	}

	img {
		border: 0;
		width: 2.3125rem;
		height: 2.3125rem;
	}

	/* 单击28颗牙齿的时候出现对号 */
	.duihao {
		background: url(../../assets/ysmimg/duihao.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 70% 70%;
	}

	/* 按钮 */
	.nextStep {
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}

	.back {
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #af9f73 !important;
		color: #af9f73 !important;
	}

	/* 单击28颗牙齿的时候出现对号 */
	.duihao {
		background: url(../../assets/ysmimg/duihao.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 70% 70%;
	}

	.hei {
		filter: grayscale(100%);
		-webkit-filter: grayscale(100%);
		-moz-filter: grayscale(100%);
		-ms-filter: grayscale(100%);
		-o-filter: grayscale(100%);
		filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
		-webkit-filter: grayscale(1);
	}

	#hei {
		filter: grayscale(100%);
		-webkit-filter: grayscale(100%);
		-moz-filter: grayscale(100%);
		-ms-filter: grayscale(100%);
		-o-filter: grayscale(100%);
		filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
		-webkit-filter: grayscale(1);
		pointer-events: none;
	}
    .gray{
		background-color: #CCCCCC !important;
	}
	.ei {
		width: 12.5rem;
		height: 12rem;
	}
</style>
